import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row q-col-gutter-x-sm q-col-gutter-y-sm"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  class: "relative-position rounded-borders overflow-hidden",
  style: {"aspect-ratio":"2/1"}
}
const _hoisted_4 = {
  key: 1,
  class: "absolute-center"
}
const _hoisted_5 = { class: "absolute-bottom-left" }
const _hoisted_6 = { class: "q-pa-md" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "full-height flex column justify-center overflow-hidden" }
const _hoisted_9 = {
  class: "flex full-width",
  style: {"column-gap":"5px"}
}
const _hoisted_10 = { class: "text-h4 ellipsis q-mb-xs" }
const _hoisted_11 = {
  key: 0,
  class: "text-small"
}
const _hoisted_12 = { class: "flex no-wrap items-center q-gutter-x-xs" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "text-small q-mb-sm ellipsis-2-lines" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  key: 1,
  class: "row q-col-gutter-x-sm q-col-gutter-y-sm"
}
const _hoisted_18 = { class: "col-12" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "full-height flex column justify-center" }
const _hoisted_21 = { class: "text-h3 ellipsis q-mb-xs" }
const _hoisted_22 = { class: "col-12" }

import {computed} from 'vue';
import CustomLink from 'components/etc/CustomLink.vue';
import ProfileName from 'components/etc/ProfileName.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DealerSearchListItem',
  props: {
  dealerData: {type: Object as any, default: undefined},
  loading: {default: false},
},
  setup(__props) {

const props = __props

const servicesDisplay = computed(() => {
  if (!props.dealerData?.services || !Array.isArray(props.dealerData.services)) return
  return props.dealerData.services.flatMap(obj => obj.name).join(' • ')
})


return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createBlock(CustomLink, {
    "no-link": !__props.dealerData,
    to: {name: 'business-profile', params: {slug: __props.dealerData?.slug}},
    class: "relative-position cursor-pointer",
    style: {"text-decoration":"none"}
  }, {
    default: _withCtx(() => [
      (!__props.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (__props.dealerData.images_all?.length)
                  ? (_openBlock(), _createBlock(_component_q_img, {
                      key: 0,
                      src: __props.dealerData.images_all[0].image.path,
                      class: "full-width full-height",
                      "no-spinner": "",
                      fit: "cover"
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true),
                (!__props.dealerData.images_all?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_q_icon, {
                        size: "xl",
                        color: "grey-3",
                        name: "fas fa-store"
                      })
                    ]))
                  : _createCommentVNode("", true),
                _cache[0] || (_cache[0] = _createElementVNode("div", {
                  class: "absolute-full",
                  style: {"background":"linear-gradient(-125deg, rgba(0, 0, 0, 0.08) 45%, rgba(0, 0, 0, 0.23) 100%)"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_q_avatar, {
                      size: "5rem",
                      color: "grey-4",
                      class: "shadow-4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_img, {
                          "no-spinner": "",
                          src: __props.dealerData.avatar?.path
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(ProfileName, { owner: __props.dealerData }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(__props.dealerData.public_name), 1)
                    ]),
                    _: 1
                  }, 8, ["owner"]),
                  (__props.dealerData.distance_in_km)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_q_icon, {
                            name: "fas fa-location-dot",
                            size: "0.6rem",
                            color: "grey-8"
                          }),
                          _createElementVNode("div", null, _toDisplayString(__props.dealerData.distance_in_km) + " km", 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                (servicesDisplay.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(servicesDisplay.value), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_16, "Keine Angabe über Leistungen"))
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_q_skeleton, {
                type: "rect",
                class: "full-height full-width rounded-borders",
                style: {"aspect-ratio":"2/1"}
              })
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_q_skeleton, {
                    type: "rect",
                    width: "65%"
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_q_skeleton, {
                    type: "text",
                    width: "80%"
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_q_skeleton, { type: "text" })
            ])
          ]))
    ]),
    _: 1
  }, 8, ["no-link", "to"])), [
    [_directive_ripple]
  ])
}
}

})