
import {PropType} from 'vue';

export interface Extra {
  name: string
  icon?: string
  value: string
}

export default {
  name: 'VehicleExtraChip',
  props: {
    modelValue: {type: Object as PropType<Extra>, required: false, default: undefined},
    active: {type: Boolean, default: false},
    chipClass: {type: String, default: ''},
  },
  emits: ['click'],
}
