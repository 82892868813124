import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "relative-position bg-grey-3 rounded-borders overflow-hidden",
  style: {"aspect-ratio":"1"}
}
const _hoisted_2 = { class: "q-mt-md" }
const _hoisted_3 = { class: "flex no-wrap q-gutter-x-sm" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col-shrink" }
const _hoisted_6 = { class: "ellipsis text-bold" }
const _hoisted_7 = {
  key: 0,
  class: "text-small text-grey-7 flex items-center",
  style: {"column-gap":"0.7rem"}
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { class: "flex text-small q-gutter-x-xs no-wrap" }
const _hoisted_13 = { class: "col-shrink" }
const _hoisted_14 = { class: "text-grey-8 ellipsis" }
const _hoisted_15 = { class: "flex q-gutter-x-xs q-mt-xs" }
const _hoisted_16 = {
  class: "text-bold",
  style: {"white-space":"nowrap","font-size":"1rem"}
}
const _hoisted_17 = {
  class: "absolute-top-left",
  style: {"z-index":"1"}
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "flex no-wrap q-gutter-x-sm q-mt-md" }
const _hoisted_20 = { class: "col-grow" }

import {Advert} from 'src/models/advert'
import {computed, PropType, ref} from 'vue';
import CustomLink from 'src/components/etc/CustomLink.vue';
import AdvertCardImage from 'src/apps/adverts/components/AdvertCard/AdvertCardImage.vue';
import ProfileName from 'components/etc/ProfileName.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvertCard',
  props: {
  loading: {default: false, type: Boolean},
  hideSlider: {default: false, type: Boolean},
  data: {
    type: Object as PropType<Advert>,
    required: false,
    default: undefined,
  },
  isHighlighted: {
    type: Boolean,
    default: false
  },
  isVisible: {
    type: Boolean,
    default: true
  },
  target: {
    default: undefined,
    type: String
  },
  showDistance: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

const props = __props

const componentRef = ref<HTMLElement | null>(null)
const advertCardImageRef = ref(null)

const formattedMileage = computed(() => {
  if (!props.data?.vehicle?.mileage) return
  const number = props.data?.vehicle.mileage
  return new Intl.NumberFormat('de-DE', {maximumSignificantDigits: 3}).format(number);
})


return (_ctx: any,_cache: any) => {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_card = _resolveComponent("q-card")!

  return (!__props.loading && __props.data?.vehicle)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "componentRef",
        ref: componentRef,
        class: "c-advert-card relative-position"
      }, [
        _createVNode(CustomLink, {
          to: (__props.data?.active || !__props.data?.deleted) && __props.data?.uuid ? { name: 'listingDetail', params: { slug: __props.data.seo_slug, uuid: __props.data.uuid, } } : undefined,
          class: "c-card-link-wrapper",
          target: __props.target
        }, {
          default: _withCtx(() => [
            (__props.isVisible)
              ? (_openBlock(), _createBlock(AdvertCardImage, _mergeProps({
                  key: 0,
                  ref_key: "advertCardImageRef",
                  ref: advertCardImageRef
                }, _ctx.$props, {
                  data: __props.data,
                  "has-slider": !__props.hideSlider
                }), _createSlots({ _: 2 }, [
                  (_ctx.$slots.actions)
                    ? {
                        name: "topRight",
                        fn: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "actions")
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1040, ["data", "has-slider"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_q_skeleton, {
                    square: "",
                    style: {"aspect-ratio":"1"},
                    animation: "blink"
                  })
                ])),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createVNode(_component_q_avatar, {
                    class: "bg-grey-3",
                    size: "md"
                  }, {
                    default: _withCtx(() => [
                      (__props.data?.by_user)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            alt: "user avatar",
                            src: __props.data.by_user?.avatar?.path
                          }, null, 8, _hoisted_4))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(__props.data.title), 1),
                  (__props.data?.vehicle?.beds || __props.data?.vehicle.seats || formattedMileage.value || __props.data?.vehicle.year)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (__props.data?.vehicle?.year)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(__props.data.vehicle.year), 1))
                          : _createCommentVNode("", true),
                        (__props.data?.vehicle?.seats)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_q_icon, { name: "airline_seat_recline_normal" }),
                              _createTextVNode(" " + _toDisplayString(__props.data.vehicle.seats), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (__props.data?.vehicle?.beds)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(_component_q_icon, {
                                name: "fas fa-bed",
                                size: "0.7rem"
                              }),
                              _createTextVNode("  " + _toDisplayString(__props.data.vehicle.beds), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (formattedMileage.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createVNode(_component_q_icon, {
                                name: "fas fa-gauge",
                                size: "0.7rem"
                              }),
                              _createTextVNode(" " + _toDisplayString(formattedMileage.value) + " km ", 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(ProfileName, {
                        owner: __props.data?.by_user,
                        target: __props.target,
                        class: "full-width",
                        truncate: ""
                      }, {
                        name: _withCtx(({user}) => [
                          _createElementVNode("span", null, _toDisplayString(user.public_name ?? user.name), 1)
                        ]),
                        _: 1
                      }, 8, ["owner", "target"])
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("div", null, "•", -1)),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_14, [
                        _createTextVNode(_toDisplayString(__props.data.address?.city) + " ", 1),
                        (__props.showDistance && __props.data?.distance_in_km)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(" • " + _toDisplayString(__props.data?.distance_in_km) + " km entfernt ", 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createTextVNode(_toDisplayString(!__props.data.price ? '- €' :
                    _ctx.$filters.currency({
                      value: __props.data.price,
                      minimumFractionDigits: 0,
                    })) + " ", 1),
                      (__props.data?.is_negotiable)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode("VB")
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _: 3
        }, 8, ["to", "target"]),
        _createElementVNode("div", _hoisted_17, [
          (__props.data?.is_new)
            ? (_openBlock(), _createBlock(_component_q_badge, {
                key: 0,
                class: "bg-warning text-uppercase",
                outline: "",
                style: {"margin-left":"-5px","margin-top":"8px"}
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Neu ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ], 512))
    : (_openBlock(), _createElementBlock("div", _hoisted_18, [
        _createVNode(_component_q_card, {
          flat: "",
          bordered: "",
          class: _normalizeClass(["overflow-hidden q-hoverable cursor-pointer", { 'c-highlighted': __props.isHighlighted }])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_skeleton, {
              square: "",
              style: {"aspect-ratio":"1"},
              animation: "blink"
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", null, [
            _createVNode(_component_q_skeleton, {
              type: "QAvatar",
              size: '32px'
            })
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                style: {"width":"90%"}
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_q_skeleton, {
                type: "text",
                style: {"width":"70%"}
              }),
              _createVNode(_component_q_skeleton, {
                type: "text",
                style: {"width":"35%","height":"24px"}
              })
            ])
          ])
        ])
      ]))
}
}

})