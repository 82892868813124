import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {onMounted, ref, watch} from 'vue';
import {useSearchStore} from 'src/apps/search/store';
import {debounce} from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchWrapperLayout',
  setup(__props) {

const searchStore = useSearchStore()
const isInitChange = ref(true)


const fetchData = debounce(searchStore._fetchData, 200);

onMounted(() => {
  fetchData()
})

watch(
  () => searchStore.filterData,
  () => {
    console.debug('filterData changed.')

    const abortController = searchStore.getAbortController
    if (abortController) {
      abortController[searchStore.searchType].abort()
      abortController[searchStore.searchType] = new AbortController()
    }

    fetchData(true)
    isInitChange.value = false;
    if (window) window.scrollTo(0, 0)
  },
  {deep: true}
)


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})