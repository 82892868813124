import script from "./BlogFeed.vue?vue&type=script&setup=true&lang=ts"
export * from "./BlogFeed.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import Ripple from 'quasar/src/directives/ripple/Ripple.js';
import qInstall from "../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QBtn,QSeparator});qInstall(script, 'directives', {Ripple});
