import {api} from 'boot/axios';
import {AxiosResponse} from 'axios';

export default {
  getBlogFeed: function (): Promise<AxiosResponse<any>> {
    let url = process.env.BASE_URL
    if (process.env.DEV) {
      url = 'http://localhost:8001'
    }

    return api.get(`${url}/blog/json/`)
  },
}
