import script from "./SelectReasonForm.vue?vue&type=script&lang=ts&setup=true"
export * from "./SelectReasonForm.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QOptionGroup,QInput});
