import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "flex no-wrap",
  style: {"column-gap":"14px"}
}
const _hoisted_2 = { class: "col-grow" }
const _hoisted_3 = { id: "map" }
const _hoisted_4 = { class: "flex column no-wrap full-height q-gutter-y-md" }
const _hoisted_5 = { class: "q-mx-lg" }
const _hoisted_6 = { class: "flex justify-center" }

import {computed, onMounted, ref, toRefs} from 'vue';
import 'leaflet/dist/leaflet.css';
import {getCssVar, useDialogPluginComponent, useQuasar} from 'quasar';
import {LCircle, LMap, LMarker, LTileLayer} from '@vue-leaflet/vue-leaflet';
import {LatLngTuple} from 'leaflet';
import {api} from 'boot/axios';
import {locate} from 'src/apps/core/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationSelectDialog',
  props: {
  radius: {
    required: false,
    type: Number,
    default: undefined
  },
  latLng: {
    required: false
  }
},
  emits: [
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
],
  setup(__props) {

const {dialogRef, onDialogOK, onDialogHide} = useDialogPluginComponent();
const $q = useQuasar();

const props = __props

const {radius, latLng} = toRefs(props)

const zoom = ref(4);
const center = ref(latLng?.value || [51.1642292, 10.4541194]); // Default center coordinates
const marker = ref<LatLngTuple>(center.value as LatLngTuple);
const mapRef = ref<any>(null)
const sliderRef = ref(null)
const circleRef = ref<any>(null)
const steps = [50_000, 100_000, 200_000, 300_000, 500_000, null];
const sliderValue = ref(steps.length - 2) // Represents the index of the steps array

const radiusCmp = computed(() => steps[sliderValue.value]);

const searchLocation = ref()
const autocompleteOptions = ref([])
const loadingLocation = ref(false)

const mapOptions = ref({
  doubleClickZoom: false
})



const filterFn = (val, update, abort) => {
  if (val.length < 2) {
    abort()
    return
  }

  loadingLocation.value = true
  api.post('address-autocomplete/', {input: val}).then(resp => {
    update(() => {
      autocompleteOptions.value = resp.data.flatMap(obj => obj.description)
    })
  }).finally(() => {
    abort()
    loadingLocation.value = false
  })
}

function mapClick(event) {
  updateMarker([event.latlng.lat, event.latlng.lng]);
}

function updateMarker(latlng) {
  marker.value = latlng;
  center.value = latlng;
  setTimeout(() => {
    onRadiusChange()
  }, 300)
}


const onRadiusChange = async function () {
  // Check if mapRef and marker are initialized
  if (mapRef.value && marker.value) {
    // when radius is not set
    if (radiusCmp.value === null) {
      return
    }

    if (!circleRef.value) return
    const bounds = circleRef.value.leafletObject.getBounds();
    // // Adding padding to the bounds
    const paddedBounds = bounds.pad(0.20)
    // Set the map view to the new bounds
    mapRef.value.leafletObject.fitBounds(paddedBounds);
  } else {
    console.error('Map or marker not initialized');
  }
}

onMounted(() => {
  if (radius?.value && steps.indexOf(radius?.value * 1000) !== -1) {
    sliderValue.value = steps.indexOf(radius.value * 1000)

    setTimeout(() => {
      onRadiusChange()
    }, 300)
  }
})

function formatLabel(value) {
  return `${value / 1000} km`;
}

function locatePosition() {
  loadingLocation.value = true
  locate($q).then(pos => {
    const latlng = [pos.latitude, pos.longitude];
    updateMarker(latlng);
  }).catch(err => {
    $q.notify({message: err.message});
    console.error(err)
  }).finally(() => {
    loadingLocation.value = false
  })

  if (sliderRef.value) {
    (sliderRef.value as any).$el.focus()
  }
}

const onOkClick = () => {
  $q.loading.show()
  api.post('address-reverse-geocode/', {lat: center.value[0], lng: center.value[1]}).then(resp => {
    onDialogOK({
      radius: radiusCmp.value ? radiusCmp.value / 1000 : undefined,
      latLng: center.value,
      address: resp.data
    })
  }).finally(() => {
    $q.loading.hide()
  })
}

const onAddressUpdate = (val) => {
  loadingLocation.value = true
  api.post('address-location/', {address: val}).then(resp => {
    if (resp.data.latitude && resp.data.longitude) {
      updateMarker([resp.data.latitude, resp.data.longitude])
    } else {
      console.info('Invalid response', resp)
    }
  }).finally(() => {
    loadingLocation.value = false
  })
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_slider = _resolveComponent("q-slider")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    maximized: _unref($q).screen.lt.sm,
    "transition-show": "slide-up",
    "transition-hide": "slide-down",
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "q-dialog-plugin flex column",
        style: {"min-height":"60vh","min-width":"50vw","max-width":"600px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", null, [
                  _withDirectives(_createVNode(_component_q_btn, {
                    flat: "",
                    icon: "fas fa-close",
                    round: "",
                    "aria-label": "close"
                  }, null, 512), [
                    [_directive_close_popup]
                  ])
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_select, {
                    modelValue: searchLocation.value,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((searchLocation).value = $event)),
                      onAddressUpdate
                    ],
                    color: "grey-6",
                    "hide-dropdown-icon": true,
                    options: autocompleteOptions.value,
                    loading: loadingLocation.value,
                    behavior: "menu",
                    dense: "",
                    clearable: "",
                    placeholder: "Addresse suchen",
                    "fill-input": "",
                    "hide-selected": "",
                    "input-debounce": "350",
                    "stack-label": "",
                    "use-input": "",
                    outlined: "",
                    rounded: "",
                    onFilter: filterFn
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        size: "xs",
                        class: "fas fa-search"
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options", "loading"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pa-none col relative-position" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(LMap), {
                  ref_key: "mapRef",
                  ref: mapRef,
                  zoom: zoom.value,
                  center: center.value,
                  class: "map",
                  options: mapOptions.value,
                  onClick: mapClick
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(LMarker), { "lat-lng": marker.value }, null, 8, ["lat-lng"]),
                    (radiusCmp.value !== null)
                      ? (_openBlock(), _createBlock(_unref(LCircle), {
                          key: 0,
                          ref_key: "circleRef",
                          ref: circleRef,
                          "lat-lng": center.value,
                          radius: radiusCmp.value,
                          "fill-color": _unref(getCssVar)('secondary') ?? 'blue',
                          color: _unref(getCssVar)('secondary') ?? 'blue',
                          opacity: 0.5,
                          "fill-opacity": 0.12
                        }, null, 8, ["lat-lng", "radius", "fill-color", "color"]))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(LTileLayer), {
                      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                      "layer-type": "base",
                      name: "OpenStreetMap"
                    }),
                    _createVNode(_component_q_btn, {
                      class: "absolute-top-right no-focus",
                      style: {"z-index":"1000","margin":"10px"},
                      round: "",
                      color: "white",
                      "text-color": "text-black",
                      onClick: locatePosition
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, { class: "fas fa-location-crosshairs" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["zoom", "center", "options"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "col-shrink" }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-center text-small" }, "Klick um Position zu wählen", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_slider, {
                    ref_key: "sliderRef",
                    ref: sliderRef,
                    modelValue: sliderValue.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sliderValue).value = $event)),
                    min: 0,
                    max: steps.length - 1,
                    snap: "",
                    "marker-labels": "",
                    "switch-marker-labels-side": "",
                    markers: "",
                    color: "grey-8",
                    "track-size": "12px",
                    "thumb-size": "30px",
                    class: "q-pt-md",
                    onChange: onRadiusChange
                  }, {
                    "marker-label-group": _withCtx(({ markerMap }) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["row items-center no-wrap", markerMap[sliderValue.value]?.classes]),
                        style: _normalizeStyle(markerMap[sliderValue.value]?.style)
                      }, [
                        _createVNode(_component_q_chip, {
                          "text-color": "white",
                          color: "primary",
                          style: {"white-space":"nowrap"}
                        }, {
                          default: _withCtx(() => [
                            (steps[sliderValue.value] === null)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(" Überall ")
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(formatLabel(steps[sliderValue.value])), 1)
                                ], 64))
                          ]),
                          _: 1
                        })
                      ], 6)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "max"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_btn, {
                    label: radiusCmp.value !== null ? `Im Umkreis von ${radiusCmp.value / 1000} km suchen` : 'Überall suchen',
                    class: "q-py-sm",
                    color: "primary",
                    rounded: "",
                    "no-caps": "",
                    unelevated: "",
                    onClick: onOkClick
                  }, null, 8, ["label"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["maximized", "onHide"]))
}
}

})