import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import {computed, nextTick, onBeforeUnmount, onMounted, provide, ref, watch,} from 'vue'
import {useMeta, useQuasar} from 'quasar'
import {getPageUrl, updateParams} from 'src/etc/helper'
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router'
import {SearchInject, searchSymbol} from 'src/apps/core/injectionKeys'
import SearchPageContent from 'src/apps/search/components/searchPageComponents/searchType/SearchContent.vue'
import DownloadAppNotification from 'src/apps/core/components/DownloadAppNotification.vue'
import SearchWrapper from 'src/apps/search/components/SearchWrapper.vue'
import {SearchType} from 'src/apps/search/types';



import {useMainStore} from 'stores/main';
import {useSearchStore} from 'src/apps/search/store';

const __default__ = {
  async preFetch({store}) {
    const mainStore = useMainStore(store)
    await mainStore.fetchBusinessServices()
    return await mainStore.fetchBusinessServicesAvailable()
  }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'SearchPage',
  setup(__props) {

const route = useRoute()
const router = useRouter()
const searchStore = useSearchStore()
const $q = useQuasar()
const isVisibleFooterMenu = ref(false)
const showMapBtn = ref(false)

const searchType = computed({
  get() {
    return searchStore.searchType
  },
  set(val: SearchType) {
    searchStore.updateSearchType(val)
  },
})

function handleFooterMenuToggle(mutations) {
  mutations.forEach((mutation) => {
    if (mutation.attributeName === 'class') {
      const targetElement = mutation.target
      const isVisible = targetElement.style.display !== 'none'
      const hasHiddenClass =
        targetElement.classList.contains('q-footer--hidden')
      isVisibleFooterMenu.value = isVisible && !hasHiddenClass
    }
  })
}

let mutationObserver
onMounted(() => {

  nextTick(() => {
    const footer = document.querySelector('#c-footer-menu')
    if (footer) {
      const hasHiddenClass = footer.classList.contains('q-footer--hidden')
      isVisibleFooterMenu.value = !hasHiddenClass

      mutationObserver = new MutationObserver(handleFooterMenuToggle)
      mutationObserver.observe(footer, {
        attributes: true,
        attributeFilter: ['class'],
      })
    } else {
      console.warn('footer not found')
    }
  })

  // for aesthetics only
  setTimeout(() => {
    showMapBtn.value = true
  }, 500)
})

onBeforeUnmount(() => {
  if (mutationObserver) mutationObserver.disconnect()
})

watch(
  () => [
    searchStore.filterData,
    searchType.value,
    searchStore.activeCategory?.name,
  ],
  (value, oldValue) => {
    const searchType = value[1]
    const searchTypeOld = oldValue[1]

    const activeCat = value[2]
    const activeCatOld = oldValue[2]

    const params = {
      s: searchStore.filterData.s,
      l: searchStore.filterData.l,
      r: searchStore.filterData.r,
    }

    if (!searchStore.filterData.l) {
      searchStore.searchLocation = undefined
    }

    if (activeCatOld !== activeCat) {
      params['cat'] = activeCat
    }
    if (searchTypeOld !== searchType) {
      params['t'] = searchType
    }

    updateParams(route, router, params, true)
  },
  { immediate: false, deep: true }
)

function updateSearchType(val: SearchType) {
  searchStore.updateSearchType(val)
}

provide<SearchInject>(searchSymbol, {
  searchType: searchType,
  updateSearchType,
})

watch(
  () => searchType.value,
  () => {
    if (window) {
      window.scrollTo(0, 0)
    } else {
      console.error('no window for scrollTo')
    }
  }
)

onBeforeRouteLeave((to, from, next) => {
  if (window.scrollY) {
    searchStore.updateScrollPos(window.scrollY)
  }
  next()
})

useMeta(() => {
  let description =
    'Finde den perfekten Campervan. ' +
    'Ob Solaranlage, Dusche, Ofen, Gastank oder Markise, ' +
    'mit unseren Such-Filtern findest du genau das was du suchst.'

  let _title = 'Campervan finden'

  let canonicalLink = getPageUrl(route.name, router) || ''

  return {
    // sets document title
    titleTemplate(title) {
      return `${_title} | ${title}`
    },

    // meta tags
    meta: {
      description: {
        name: 'description',
        content: description,
      },

      ogTitle: {
        property: 'og:title',
        // optional; similar to titleTemplate, but allows templating with other meta properties
        template() {
          return `${_title} auf Camper Trader`
        },
      },
    },

    link: {
      canonical: {
        rel: 'canonical',
        href: canonicalLink,
      },
    },

    noscript: {
      default: 'Please enable Javascript to use this website.',
    },
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_unref($q).platform.is.mobile && !_unref($q).platform.is.nativeMobile)
          ? (_openBlock(), _createBlock(DownloadAppNotification, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(SearchWrapper),
        (_openBlock(), _createBlock(SearchPageContent, { key: searchType.value }))
      ])
    ]),
    _: 1
  }))
}
}

})