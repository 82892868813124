import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_chip = _resolveComponent("q-chip")!

  return ($props.modelValue)
    ? (_openBlock(), _createBlock(_component_q_chip, _mergeProps({
        key: 0,
        color: $props.active ? 'positive' : undefined,
        "text-color": $props.active ? 'white' : undefined,
        clickable: ""
      }, _ctx.$attrs, {
        class: ["q-ma-none", $props.chipClass],
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $props.modelValue)))
      }), {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString($props.modelValue.icon) + " " + _toDisplayString($props.modelValue.name), 1)
        ]),
        _: 1
      }, 16, ["color", "text-color", "class"]))
    : _createCommentVNode("", true)
}