
import {computed, onMounted, ref, watch} from 'vue';
import {Advert} from 'src/models/advert';
import {date, Loading, Notify, QSpinnerHourglass, useMeta, useQuasar,} from 'quasar';
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
import AdvertsApi from 'src/api/adverts';
import {useMainStore} from 'src/stores/main';
import deepmerge from 'deepmerge';
import AddressStep from 'src/components/adverts/createUpdateSteps/AddressStep.vue';
import VehicleEquipmentStep from 'src/components/adverts/createUpdateSteps/VehicleEquipmentStep.vue';
import ExtrasStep from 'src/components/adverts/createUpdateSteps/ExtrasStep.vue';
import ImagesStep from 'src/components/adverts/createUpdateSteps/ImagesStep.vue';
import BasisDataStep from 'src/components/adverts/createUpdateSteps/BasisDataStep.vue';
import {stepHasError} from 'src/components/adverts/createUpdateSteps/StepFieldMapping';
import {limitReachedRoute} from 'src/etc/helper';

export default {
  name: 'CreateUpdateAdvertPage',
  components: {
    BasisDataStep,
    ImagesStep,
    ExtrasStep,
    VehicleEquipmentStep,
    AddressStep,
  },
  async preFetch({store, previousRoute, currentRoute, redirect}) {
    const mainStore = useMainStore(store);
    if (previousRoute?.name === currentRoute.name) return;

    await mainStore.fetchAccountDetails()
    const countAdverts = mainStore.accountDetail?.active_account?.current_usage?.advert_limit;
    const advertLimit: number | undefined =
      (mainStore.accountDetail?.active_account?.limits?.advert_limit ?? 0) +
      (mainStore.accountDetail?.active_account?.unused_quota?.advert_limit ?? 0)
    if (
      currentRoute.name === 'createAdvert' &&
      advertLimit !== undefined && countAdverts !== undefined &&
      Number.isInteger(advertLimit) &&
      countAdverts >= advertLimit
    ) {
      console.warn('Limit reached for adverts');
      return redirect(limitReachedRoute('advertLimit'));
    }

    let canDuplicate = false
    const duplicateUuid = currentRoute.query?.duplicate
    const activeAcc = mainStore.accountDetail?.active_account
    if (duplicateUuid && activeAcc?.uuid) {
      const resp = await mainStore.fetchAdvert(duplicateUuid)

      if (activeAcc.uuid === resp.data.by_business && !resp.data.has_expired) {
        canDuplicate = true
      }
    }

    // get data if edit
    if (currentRoute.params?.uuid || canDuplicate) {
      const resp = await mainStore.fetchEditAdvert(currentRoute.params.uuid || duplicateUuid);
      if (!resp.data.is_owner) {
        redirect({name: '404'})
      }
    }
  },

  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const preventLeave = ref(true);
    const stepper = ref<any>(null);
    const mainStore = useMainStore();
    const submitted = ref(false);
    const extrasFoundInText = ref();
    const $q = useQuasar();
    const creating = ref(false)

    const beforeUnloadListener = (event) => {
      if (!preventLeave.value) {
        return event;
      }
      event.preventDefault();
      return (event.returnValue = 'Are you sure you want to exit?');
    };

    onMounted(() => {
      if (!process.env.DEV) {
        window.addEventListener('beforeunload', beforeUnloadListener, {
          capture: true,
        });
      }
    });

    const resetFormData = function () {
      formData.value = {
        active: true,
        address: {},
        vehicle: {
          equipment: {},
          figures: {},
          extras: [],
        } as any,
        contact: {},
      } as Advert;
    };

    // // same as beforeRouteLeave option with no access to `this`
    onBeforeRouteLeave(() => {
      window.removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      });
      if (!process.env.DEV) {
        if (!preventLeave.value) {
          return;
        }
        const answer = window.confirm(
          'Möchtest du die Seite wirklich verlassen? Ungespeicherte Daten gehen dabei verloren.'
        );
        // cancel the navigation and stay on the same page
        if (!answer) return false;
      }

      resetFormData()
    });

    const fields = ref();
    const files = ref<Array<File>>([]);
    const errors = ref<Advert | object>({});
    const formData = ref({
      address: {},
      vehicle: {
        equipment: {},
        figures: {},
        extras: [],
      } as any,
      contact: {},
    } as Advert);


    let uuid = ref($route.params?.uuid?.toString());
    const duplicate = $route.query?.duplicate?.toString();

    const unsetPK = function (obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          unsetPK(obj[key]);  // recursive call for 'object' properties
        }
        if (['pk', 'id'].includes(key)) {
          obj[key] = undefined;  // set 'pk' to undefined
        }
      }
    }

    const setUpData = function () {
      if (uuid.value || (duplicate && mainStore.editAdverts[duplicate as string]?.uuid)) {
        if (duplicate) {
          uuid.value = duplicate
        }
        formData.value = deepmerge(
          formData.value,
          mainStore.editAdverts[uuid.value as string]
        ) as any;
        if (!formData.value?.vehicle?.equipment) {
          formData.value.vehicle = {equipment: {}} as any;
        }
        if (duplicate) {
          unsetPK(formData.value)
          // reset images
          formData.value.images = []
        }
      } else {
        resetFormData();
      }
    }
    setUpData()

    const step = ref();
    step.value = $route.query.step
      ? Number.parseInt($route.query.step.toString())
      : 1;

    const getSelectValue = (obj) => {
      return obj.value;
    };

    const minYearMonth = computed(() => {
      return date.formatDate(new Date(), 'YYYY/MM');
    });


    const isUpdate = computed(() => {
      return !!$route.params.uuid;
    });

    creating.value = !isUpdate.value

    const scrollToError = function () {
      let ele = document.querySelector('.q-field--error');
      if (ele) {
        ele.scrollIntoView({block: 'center'});
      } else if (errors.value) {
        window.scrollTo(0, 0);
      }
    };

    const submit = async function (redirect = true, goToStep = undefined) {
      // reset errors
      errors.value = {}

      Loading.show({
        spinner: QSpinnerHourglass,
        message: 'Eingaben werden verarbeitet ...',
      });

      // fix for extras
      if (formData.value?.vehicle?.extras?.length) {
        formData.value.vehicle.extras = formData.value.vehicle.extras.filter(
          (obj) => obj['slug'] !== undefined
        );
      }

      // change masked values (monkeypatch)
      if (formData.value.vehicle.general_inspection_until === '__/____') {
        formData.value.vehicle.general_inspection_until = null;
      }
      if (formData.value.vehicle.registration === '__/____') {
        formData.value.vehicle.registration = null;
      }

      let apiCall;

      if (isUpdate.value) {
        apiCall = AdvertsApi.updateAdvert(formData.value, $route.params.uuid);
      } else {
        apiCall = AdvertsApi.createAdvert(formData.value);
      }

      return apiCall
        .then(async (resp) => {
          uuid.value = resp.data.uuid
          preventLeave.value = false;

          // matomo track
          try {
            const _paq = (window as any)._paq || [];
            _paq.push([
              'trackEvent',
              'User',
              isUpdate.value ? 'AdvertUpdated' : 'AdvertCreated',
              resp.data.uuid,
            ]);
          } catch (e) {
            console.error(e);
          }

          if (redirect) {
            if (creating.value) {
              // await AdvertsApi.setAdvertStatus(resp.data.uuid, true)
              await $router.push({
                name: 'createAdvertFinish',
                params: {uuid: resp.data.uuid},
              });
            } else {
              await $router.push({
                name: 'listingDetail',
                params: {slug: resp.data.seo_slug, uuid: resp.data.uuid},
              });
            }
          } else if (!$route.params.uuid) {
            await $router.replace({
              name: 'editAdvert',
              params: {uuid: uuid.value}, query: {step: goToStep}
            })
            const resp = await mainStore.fetchEditAdvert(uuid.value);
            formData.value = resp.data
            step.value = goToStep
          }

          submitted.value = true
          return resp
        })
        .catch((err) => {
          if (err.response?.data) {
            errors.value = err.response.data;

            for (let i = 1; i < 6; i++) {
              if (hasError(i)) {
                step.value = i;
                break;
              }
            }
            setTimeout(() => scrollToError(), 400);
          } else {
            Notify.create({
              color: 'negative',
              message: err.message
                ? err.message
                : 'Es ist leider ein Fehler aufgetreten.',
            });
          }

          return err
        })
        .finally(() => {
          Loading.hide();
        });
    };

    watch(
      () => step.value,
      (val) => {
        if (val === 5 && !uuid.value) {
          step.value = 4
        }
      },
      {immediate: true}
    )

    const checkExtrasInText = function () {
      if (extrasFoundInText.value?.length) {
        const dialogHtml = `
<div>Du hast in deiner Beschreibung
<span class="text-bold">${extrasFoundInText?.value
          .flatMap((obj) => obj.name)
          .join(', ')}</span> verwendet</span></div>
<div class="q-mt-md">Damit deine Anzeige über die Suche gefunden werden kann solltest du diese Info als "Ausstattung" angeben.</div>`;

        $q.dialog({
          html: true,
          message: dialogHtml,
          title: 'Ausstattung vergessen?',
          ok: {
            label: 'Trotzdem Fortfahren',
            flat: true,
            color: 'default',
          },
          cancel: {
            label: 'Nochmal bearbeiten',
            flat: true,
            color: 'primary',
          },
        }).onOk(() => {
          step.value = step.value + 1;
        });
      } else {
        step.value = step.value + 1;
      }
    };

    const hasError = function (stepNum) {
      return stepHasError(stepNum, errors.value);
    };

    const changeStep = function (val) {
      $router.replace({query: {step: val}});
    };

    useMeta(() => {
      return {
        // sets document title
        titleTemplate(title) {
          return `Inserat erstellen | ${title}`;
        },

        noscript: {
          default: 'Please enable Javascript to use this website.',
        },
      };
    });

    const getFieldOptions = () => {
      AdvertsApi.getAdvertOptions()
        .then((resp) => {
          fields.value = resp.data.actions.POST;
        })
        .catch((err) => {
          console.error(err);
        });
    };
    getFieldOptions();

    return {
      stepper,
      step,
      formData,
      minYearMonth,
      submit,
      files,
      errors,
      getSelectValue,
      fields,
      changeStep,
      isUpdate,
      beforeUnloadListener,
      hasError,
      submitted,
      extrasFoundInText,
      checkExtrasInText,
      uuid,
      creating,
      mainStore
    };
  },
};
