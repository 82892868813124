import script from "./ExtrasFilterDialog.vue?vue&type=script&lang=ts&setup=true"
export * from "./ExtrasFilterDialog.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QChip,QInput,QSkeleton,QBtn});
