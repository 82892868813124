import script from "./TestimonialWidget.vue?vue&type=script&lang=ts&setup=true"
export * from "./TestimonialWidget.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QAvatar,QImg,QRating,QIcon});
