import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex column justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-bold"
}
const _hoisted_3 = {
  class: "flex items-center no-wrap",
  style: {"column-gap":"12px"}
}
const _hoisted_4 = {
  style: {"color":"#FBBC04"},
  class: "text-bold c-label-stars"
}
const _hoisted_5 = {
  key: 1,
  class: "text-muted text-xsmall"
}

import {computed, PropType} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleReviewWidget',
  props: {
  placeDetails: {required: false, type: Object},
  rating: {required: true, type: Number},
  userRatingsTotal: Number,
  size: {
    type: String as PropType<'sm' | 'md'>,
    default: 'md'
  },
  hideRatingsCount: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {


const props = __props

const ratingSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return '1.5rem';
    case 'md':
      return '2rem';
    default:
      return '2rem'
  }
})

const iconSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return '1.75rem';
    case 'md':
      return '2.2rem';
    default:
      return '2.2rem'
  }
})

const ratingStars = computed(() => {
  let _rating = props.rating
  try {
    return Math.round(_rating)
  } catch (e) {
    console.error(e)
  }
  return 5
})


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_rating = _resolveComponent("q-rating")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center", `size-${__props.size}`]),
    style: {"column-gap":"12px"}
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_q_icon, {
        name: "fab fa-google colored",
        size: iconSize.value
      }, null, 8, ["size"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      (props.size !== 'sm')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Google Business Reviews"))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(__props.rating), 1),
        _createElementVNode("div", null, [
          _createVNode(_component_q_rating, {
            size: ratingSize.value,
            readonly: "",
            "model-value": ratingStars.value,
            style: {"color":"#FBBC04"},
            icon: "star"
          }, null, 8, ["size", "model-value"])
        ])
      ]),
      (!__props.hideRatingsCount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(__props.userRatingsTotal) + " Bewertungen insgesamt", 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})