<template>
  <q-btn
    :disable="Object.keys(filter).length < 1"
    color="primary" outline unelevated @click="setSearchAgent()">
    {{ label }}
    <template v-if="mainStore.isLoggedIn">
      &nbsp;({{ searchAgents?.length }}/{{ searchAgentLimit }})
    </template>
  </q-btn>
</template>

<script setup>
import Adverts from 'src/api/adverts';
import {Notify, useQuasar} from 'quasar';
import {useMainStore} from 'stores/main';
import {useRouter} from 'vue-router';
import {ref} from 'vue';
import {limitReachedRoute} from 'src/etc/helper';

const props = defineProps({
  filter: {required: false},
  label: {type: String, default: 'Suchauftrag'}
})

const $q = useQuasar()
const mainStore = useMainStore()
const router = useRouter()
const searchAgents = ref()


const getSearchAgents = function () {
  Adverts.getAdvertSearchAgents().then(resp => {
    searchAgents.value = resp.data || []
  })
}

if (mainStore.isLoggedIn) {
  getSearchAgents()
}


const setSearchAgent = function () {
  if (!mainStore.isLoggedIn) {
    $q.dialog({
      title: 'Suchauftrag anlegen',
      message: 'Du kannst Suchaufträge nur eingeloggt anlegen.'
    })
    return
  }

  if (Number.isInteger(mainStore.accountDetail?.limits?.search_agent_limit) &&
    searchAgents.value?.length >= (mainStore.accountDetail?.limits?.search_agent_limit || 0)) {
    router.push(limitReachedRoute('searchAgentLimit'))
    return
  }

  $q.dialog({
    title: 'Suchauftrag einrichten',
    message: 'Mit einem aktiven Suchauftrag senden wir dir neue Ergebnisse per E-Mail zu. ' +
      'Am besten du benennst deine Suchaufträge, um sie besser unterscheiden zu können.',
    prompt: {
      model: '',
      placeholder: 'Suchauftrag Bezeichnung',
      type: 'text' // optional
    },
    cancel: {
      label: 'Abbrechen',
      color: 'grey',
      flat: true
    },
    persistent: false
  }).onOk(data => {
    Adverts.setAdvertSearchAgent(props.filter, data).then(() => {
      Notify.create({
        type: 'positive',
        message: 'Suchauftrag wurde angelegt.',
        multiLine: true,
        actions: [
          {
            label: 'Anzeigen', color: 'white', handler: () => {
              router.push({name: 'search-agents'})
            }
          }
        ]
      })
      getSearchAgents()
    }).catch(err => {
      console.error(err);
      if (err.response?.status === 400) {
        let errors = []
        errors = errors.concat(err.response.data.non_field_errors)

        Notify.create({
          type: 'warning',
          message: errors.join(' ')
        })
      }
    })
  })
}


</script>
