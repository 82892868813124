
import {ref, toRef} from 'vue';
import {api} from 'boot/axios';
import {useRouter} from 'vue-router';
import {Notify} from 'quasar';

export default {
  name: 'LocationField',
  props: {
    modelValue: {
      required: true,
    },
    showGPS: {
      required: false,
      type: Boolean,
      default: true,
    }
  },
  emits: [
    'update:modelValue',
    'update:location'
  ],
  // emits: ['updatse:modelValue'],
  setup(props, ctx) {
    const router = useRouter()
    const modelVal = toRef(props, 'modelValue')
    const searchLocation = ref(modelVal.value)
    const autocompleteOptions = ref([])
    const filterFn = (val, update, abort) => {
      if (val.length < 2) {
        abort()
        return
      }

      api.post('address-autocomplete/', {input: val}).then(resp => {
        update(() => {
          autocompleteOptions.value = resp.data.flatMap(obj => obj.description)
        })
      }).finally(() => {
        abort()
      })
    }
    const loadingLocation = ref(false)
    const getLocation = function () {
      if (loadingLocation.value) return
      if (navigator.geolocation) {
        loadingLocation.value = true
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&accept-language=de`;
          fetch(url)
            .then(response => response.json())
            .then(data => {
              if (data && data.address) {
                const city = data.address.city || data.address.town || data.address.village || 'Unknown City';
                const country = data.address.country || 'Unknown Country';
                const location = city + ', ' + country;
                router.push({name: 'search', query: {l: location}})
                ctx.emit('update:location', location)
              } else {
                Notify.create({
                  message: 'Adresse konnte nicht ermittelt werden. Bitte versuche es später nochmal.',
                  color: 'red',
                  icon: 'error',
                  position: 'top-right',
                  timeout: 5000,
                })
              }

            })
            .catch((err) => {
              Notify.create({
                message: 'Fehler beim Abrufen deines Standorts. Bitte versuche es später nochmal.',
                color: 'red',
                icon: 'error',
                position: 'top-right',
                timeout: 5000,
              })
              console.error(err)
            }).finally(() => {
            loadingLocation.value = false
          });
        }, () => {
          Notify.create({
            message: 'Bitte erlaube den Zugriff auf deinen Standort.',
            color: 'red',
            icon: 'error',
            position: 'top-right',
            timeout: 5000,
          })
          loadingLocation.value = false
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      } else {
        Notify.create({
          message: 'Leider unterstützt dein Browser die Standortbestimmung nicht.',
          color: 'red',
          icon: 'error',
          position: 'top-right',
          timeout: 5000,
        })
        loadingLocation.value = false
      }
    }


    return {
      autocompleteOptions,
      searchLocation,
      filterFn,
      getLocation,
      loadingLocation,
    }
  }

}

