import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, TransitionGroup as _TransitionGroup, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-gutter-y-md" }
const _hoisted_2 = { class: "container q-my-lg" }
const _hoisted_3 = {
  key: 0,
  class: "row q-col-gutter-x-md q-col-gutter-y-lg"
}
const _hoisted_4 = {
  key: 1,
  class: "row q-col-gutter-md q-py-lg"
}
const _hoisted_5 = {
  key: 2,
  class: "q-my-xl"
}
const _hoisted_6 = { class: "container q-my-lg" }
const _hoisted_7 = {
  key: 0,
  class: "row q-col-gutter-x-md q-col-gutter-y-lg"
}
const _hoisted_8 = {
  key: 1,
  class: "row q-col-gutter-md q-py-lg"
}
const _hoisted_9 = {
  key: 2,
  class: "q-my-xl"
}

import AdvertCard from 'src/apps/adverts/components/AdvertCard.vue';
import Adverts from 'src/api/adverts';
import {onMounted, ref} from 'vue';
import {runSequentialPromises, useMeta} from 'quasar';
import {getPageUrl, toggleAdvertFavorite, toggleItemFavorite} from 'src/etc/helper';
import {useRouter} from 'vue-router';
import Market from 'src/api/market';
import ItemCard from 'src/apps/market/components/ItemCard.vue';
import {usePersistentLocalStore} from 'src/apps/core/persistent-store';


export default /*@__PURE__*/_defineComponent({
  __name: 'FavoritesPage',
  setup(__props) {

const persistStore = usePersistentLocalStore()
const router = useRouter()
const loading = ref(true)
const favoriteAdverts = ref([])
const favoriteItems = ref<Array<any>>([])


const fetchFavorites = function () {
  loading.value = true
  runSequentialPromises([
    () => Adverts.getUserFavorites(),
    () => Market.getUserFavorites()
  ]).then((resultAggregator) => {
    favoriteAdverts.value = resultAggregator[0].value.data
    favoriteItems.value = resultAggregator[1].value.data
    loading.value = false
  }).catch(err => {
    console.error(err)
    loading.value = false
  })
}


onMounted(() => {
  fetchFavorites()
})


const removeFav = function (fav, type: 'advert' | 'item') {
  if (type === 'advert') {
    if (fav.advert.deleted || !fav.advert.active) {
      // if advert is deleted toggle is not working, instead delete fav directly
      Adverts.removeFavorite(fav.uuid).then(() => {
        const index = favoriteAdverts.value.findIndex(obj => obj.uuid === fav.uuid)
        if (index !== -1) favoriteAdverts.value.splice(index, 1)
      })
    } else {
      toggleAdvertFavorite(fav.advert).then(() => {
        const index = favoriteAdverts.value.findIndex(obj => obj.uuid === fav.uuid)
        if (index !== -1) favoriteAdverts.value.splice(index, 1)
      })
    }
  }

  if (type === 'item') {
    if (['inactive', 'deleted'].includes(fav.item.status)) {
      // if advert is deleted toggle is not working, instead delete fav directly
      Market.removeFavorite(fav.uuid).then(() => {
        const index = favoriteItems.value.findIndex(obj => obj.uuid === fav.uuid)
        if (index !== -1) favoriteItems.value.splice(index, 1)
      })
    } else {
      toggleItemFavorite(fav.item).then(() => {
        const index = favoriteItems.value.findIndex(obj => obj.uuid === fav.uuid)
        if (index !== -1) favoriteItems.value.splice(index, 1)
      })
    }

  }
}

useMeta(() => {
  return {
    // sets document title
    titleTemplate(title) {
      return `Merkliste | ${title}`
    },

    meta: {
      description: {name: 'description', content: 'Deine Merkliste mit deinen Favoriten'},
    },

    link: {
      canonical: {
        rel: 'canonical', href: getPageUrl('favorites', router) || ''
      }
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "header__fix" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_tabs, {
          "model-value": _unref(persistStore).getFavoritesTab,
          dense: "",
          "narrow-indicator": !_ctx.$q.screen.lt.sm,
          align: _ctx.$q.screen.lt.sm ? 'justify' : undefined,
          class: "text-primary",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = val => _unref(persistStore).updateFavoritesTab(val))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab, {
              ripple: "",
              name: "item",
              "no-caps": "",
              icon: "fas fa-tags"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("div", null, "Flohmarkt", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_tab, {
              ripple: "",
              name: "advert",
              icon: "fas fa-car",
              "no-caps": "",
              label: "Fahrzeuge"
            })
          ]),
          _: 1
        }, 8, ["model-value", "narrow-indicator", "align"]),
        _createVNode(_component_q_tab_panels, {
          "model-value": _unref(persistStore).getFavoritesTab,
          animated: !loading.value
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab_panel, {
              name: "item",
              class: "q-pa-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("section", _hoisted_2, [
                  (!loading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_TransitionGroup, { "leave-active-class": "animated fadeOut" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(favoriteItems.value, (fav) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: fav.uuid,
                                class: "col-6 col-sm-3 col-lg-2"
                              }, [
                                _createElementVNode("div", null, [
                                  _createVNode(ItemCard, {
                                    modelValue: fav.item,
                                    "onUpdate:modelValue": ($event: any) => ((fav.item) = $event)
                                  }, {
                                    actions: _withCtx(() => [
                                      _createVNode(_component_q_btn, {
                                        rounded: "",
                                        color: "white",
                                        "text-color": "dark",
                                        unelevated: "",
                                        onClick: _withModifiers(($event: any) => (removeFav(fav, 'item')), ["prevent"])
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_icon, {
                                            name: "fas fa-trash",
                                            size: "0.8rem"
                                          }),
                                          _cache[2] || (_cache[2] = _createTextVNode("  Entfernen "))
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1032, ["modelValue", "onUpdate:modelValue"])
                                ])
                              ]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i) => {
                          return _createElementVNode("div", {
                            key: i,
                            class: "col-6 col-sm-3 col-lg-2"
                          }, [
                            _createVNode(ItemCard, { loading: true })
                          ])
                        }), 64))
                      ])),
                  (!favoriteItems.value?.length && !loading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Du hast noch keine Anzeigen vorgemerkt. "))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_tab_panel, {
              name: "advert",
              class: "q-pa-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("section", _hoisted_6, [
                  (!loading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_TransitionGroup, { "leave-active-class": "animated fadeOut" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(favoriteAdverts.value, (fav) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: fav.uuid,
                                class: "col-12 col-sm-4 col-md-3 col-lg-4"
                              }, [
                                _createElementVNode("div", null, [
                                  _createVNode(AdvertCard, {
                                    data: fav.advert
                                  }, {
                                    actions: _withCtx(() => [
                                      _createVNode(_component_q_btn, {
                                        rounded: "",
                                        color: "white",
                                        "text-color": "dark",
                                        unelevated: "",
                                        onClick: _withModifiers(($event: any) => (removeFav(fav, 'advert')), ["prevent"])
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_icon, {
                                            name: "fas fa-trash",
                                            size: "0.8rem"
                                          }),
                                          _cache[3] || (_cache[3] = _createTextVNode("  Entfernen "))
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1032, ["data"])
                                ])
                              ]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i) => {
                          return _createElementVNode("div", {
                            key: i,
                            class: "col-12 col-md-4 col-lg-3"
                          }, [
                            _createVNode(AdvertCard, { loading: true })
                          ])
                        }), 64))
                      ])),
                  (!favoriteAdverts.value?.length && !loading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Du hast noch keine Fahrzeuge vorgemerkt. "))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model-value", "animated"])
      ])
    ]),
    _: 1
  }))
}
}

})