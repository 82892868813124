import script from "./SignupForm1.vue?vue&type=script&setup=true&lang=ts"
export * from "./SignupForm1.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner,QBanner,QBtn});
