<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin flex column">
      <q-card-section class="full-width overflow-hidden" style="flex-grow: 1">
        <template v-if="!loading && searchAgent">
          <div v-if="searchAgent.name">
            <div class="text-h3">{{ searchAgent.name }}</div>
            <q-separator class="q-my-md"></q-separator>
          </div>
          <active-filter-badges v-model="searchAgent.filter_data" :include-search="true"/>
        </template>
        <template v-else>
          <q-spinner/>
        </template>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="OK" @click="onOKClick"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import Adverts from 'src/api/adverts';
import {onMounted, ref} from 'vue';
import ActiveFilterBadges from 'components/adverts/ActiveFilterBadges.vue';

const props = defineProps({
  searchAgentUuid: {
    type: String,
    required: true
  }
})

const searchAgent = ref()
const loading = ref(false)

function fetchSearchAgent() {
  loading.value = true
  Adverts.getAdvertSearchAgent(props.searchAgentUuid).then(resp => {
    searchAgent.value = resp.data
  }).catch((err) => {
    console.error(err)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  fetchSearchAgent()
})

defineEmits([
  ...useDialogPluginComponent.emits
])

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()

function onOKClick() {
  onDialogOK()
}


</script>
