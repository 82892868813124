<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section v-if="title">
        <div class="text-h4">{{ title }}</div>
      </q-card-section>
      <q-card-section v-if="message">
        {{ message }}
      </q-card-section>
      <q-card-section>
        <select-reason-form v-model="reason" :options="options"></select-reason-form>
      </q-card-section>
      <q-card-actions align="right">
        <slot name="actions">
          <q-btn flat label="Abbrechen" @click="onDialogCancel"/>
          <q-btn :color="btnColor" :label="btnLabel" unelevated @click="onOKClick"/>
        </slot>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import SelectReasonForm from 'src/components/utils/SelectReasonForm.vue';
import {ref} from 'vue';

const reason = ref()

defineProps({
  options: {
    default: () => [],
    type: Array
  },
  title: {required: false, default: undefined, type: String},
  message: {required: false, default: undefined, type: String},
  btnColor: {default: 'primary', type: String},
  btnLabel: {default: 'Ok', type: String},
})

defineEmits([
  ...useDialogPluginComponent.emits
])

const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()

function onOKClick() {
  onDialogOK(reason.value)
}
</script>
