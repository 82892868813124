
import {ref, watchEffect} from 'vue';
import {Notify} from 'quasar'

function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
}

export default {
  name: 'FileUploaderField',
  props: {
    multiple: {default: true, type: Boolean},
    gallery: {default: false, type: Boolean},
    label: {default: 'Drag and drop files or click here'},
    modelValue: {
      required: true,
    },
    lastOrderNum: {
      default: 0
    },
    ordering: {
      default: false,
      type: Boolean
    },
    errors: {
      type: Array
    },
    maxFiles: {
      type: Number
    },
    maxFileSizeInMB: {
      type: Number
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const fileInput = ref<HTMLInputElement>()
    const fileList = ref<Array<File>>([])
    const tmpError = ref()
    const validationErrors = ref([])

    function preventDefaults(e) {
      e.preventDefault()
    }

    const emitVal = function () {
      tmpError.value = undefined
      emit('update:modelValue', fileList.value)
    }

    const fileSize = function (file) {
      return returnFileSize(file.size)
    }

    const events = ['dragenter', 'dragover', 'dragleave', 'drop']
    events.forEach((eventName) => {
      document.body.addEventListener(eventName, preventDefaults)
    })


    const click = function () {
      if (!fileInput.value) {
        return
      }
      validationErrors.value = []
      fileInput.value.click()
    }

    const addFile = function (file: File): boolean {
      /**
       *  @return {boolean} True if added else false
       */
      if (props.maxFiles && fileList.value.length >= props.maxFiles) {
        Notify.create({
          position: 'top',
          message: 'Maximale Anzahl von Dateien erreicht.',
          color: 'warning',
          caption: `${file.name}`
        })
        // validationErrors.value.push(`"${file.name}" konnte nicht hinzugefügt werden. Maximale Anzahl von ${props.maxFiles} Dateien erreicht.`)
        return false
      }

      if (file.size > props.maxFileSizeInMB * 1024 * 1024) {
        Notify.create({
          position: 'top',
          message: `Maximale Größe von ${props.maxFileSizeInMB} MB überschritten.`,
          color: 'warning',
          caption: `${file.name}`
        })
        // validationErrors.value.push(`"${file.name}" konnte nicht hinzugefügt werden. Maximale Größe von ${props.maxFileSizeInMB} MB überschritten.`)
        return false
      }

      fileList.value.push(file)
      return true
    }

    const changeFile = function () {
      if (!fileInput.value || !fileInput.value?.files) {
        return
      }

      for (let index = 0; index < fileInput.value.files.length; index++) {
        const file: File = fileInput.value.files[index];
        addFile(file)
      }
      emitVal()
    }

    const getUrl = function (file) {
      return URL.createObjectURL(file)
    }
    const removeFile = function (index) {
      fileList.value.splice(index, 1)
      emitVal()
    }

    const drop = function (e) {
      for (let index = 0; index < e.dataTransfer.files.length; index++) {
        addFile(e.dataTransfer.files[index])
      }
      emitVal()
    }

    watchEffect(() => {
      tmpError.value = props.errors
    })

    const setPosImageUp = function (pos) {
      if (pos <= 0) return
      const element = fileList.value.splice(pos, 1)[0];
      fileList.value.splice(pos - 1, 0, element);
      emitVal()
    }

    const setPosImageDown = function (pos) {
      if (pos >= fileList.value.length) return
      const element = fileList.value.splice(pos, 1)[0];
      fileList.value.splice(pos + 1, 0, element);
      emitVal()
    }

    return {
      click,
      fileInput,
      changeFile,
      fileList,
      getUrl,
      drop,
      removeFile,
      fileSize,
      setPosImageUp,
      setPosImageDown,
      tmpError,
      validationErrors,
    }
  }
}
