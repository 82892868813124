import script from "./AboutUsPage.vue?vue&type=script&lang=ts&setup=true"
export * from "./AboutUsPage.vue?vue&type=script&lang=ts&setup=true"

import "./AboutUsPage.vue?vue&type=style&index=0&id=374aa27b&lang=scss"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QImg});
