import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import CategoryScroll from 'src/apps/search/components/CategoryScroll.vue';
import {computed, inject, ref, watch} from 'vue';
import {SearchInject, searchSymbol} from 'src/apps/core/injectionKeys';
import {useSearchStore} from 'src/apps/search/store';
import {CategoryItem} from 'src/apps/search/types';
import {isSubset} from 'src/etc/helper';
import {useSearchTypeCategories} from 'src/apps/search/categories';


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryScrollWrapper',
  setup(__props) {

const {searchType} = inject(searchSymbol) as SearchInject
const searchStore = useSearchStore()
const catScroll = ref<any>(null)

const {getSearchTypeCategories, getSearchTypeCategory} = useSearchTypeCategories()

const items = computed((): Array<CategoryItem> | undefined => {
  const category = getSearchTypeCategories(searchType.value)
  if (!searchType.value || !category || !Array.isArray(category)) return
  return [
    {label: 'Alle', name: undefined,},
    ...category
  ]
})

// const activeCategory = ref<CategoryItem | undefined>()

const activeCategory = computed({
  get() {
    return searchStore.activeCategory
  },
  set(val) {
    searchStore.setActiveCategory(val)
  }
})


function onUpdate() {
  searchStore.searchLoading = true
}


watch(
  () => searchStore.filterData,
  () => {
    let _catFilter
    try {
      _catFilter = getSearchTypeCategory(searchStore.searchType, activeCategory.value?.name).filterData
    } catch (e) {
      console.error('error', e)
      return
    }
    if (!isSubset(_catFilter, searchStore.filterData)) {
      console.debug('is not subset', _catFilter, searchStore.filterData)
      searchStore.activeCategory = undefined
      if (catScroll.value) {
        catScroll.value.resetScroll()
      }
    }
  }, {deep: true}
)

return (_ctx: any,_cache: any) => {
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createBlock(_component_q_no_ssr, null, {
    default: _withCtx(() => [
      (items.value?.length)
        ? (_openBlock(), _createBlock(CategoryScroll, {
            ref_key: "catScroll",
            ref: catScroll,
            key: "cats",
            modelValue: activeCategory.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((activeCategory).value = $event)),
              onUpdate
            ],
            items: items.value
          }, null, 8, ["modelValue", "items"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})