import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "full-width text-center q-py-lg q-mt-xl"
}

import {computed, defineAsyncComponent, ref, watch} from 'vue';
import {useSearchStore} from 'src/apps/search/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchContent',
  setup(__props) {

const AllSearch = defineAsyncComponent(() => import('src/apps/search/components/searchPageComponents/searchType/AllSearch.vue'))
const VehicleSearch = defineAsyncComponent(() => import('src/apps/search/components/searchPageComponents/searchType/VehicleSearch.vue'))
const DealerSearch = defineAsyncComponent(() => import('src/apps/search/components/searchPageComponents/searchType/DealerSearch.vue'))
const MarketSearch = defineAsyncComponent(() => import('src/apps/search/components/searchPageComponents/searchType/MarketSearch.vue'))
// const MarketSearchPreview = defineAsyncComponent(() => import('src/apps/search/components/searchPageComponents/searchType/MarketSearchPreview.vue'))

const searchComponent = ref<any | null>(null)

const componentMapping = {
  'explore': AllSearch,
  'vehicle': VehicleSearch,
  'dealer': DealerSearch,
  'market': MarketSearch,
}
const searchStore = useSearchStore()

async function onLoad(index, done) {
  await searchStore._fetchData()
  done()
}

watch(
  () => searchComponent.value,
  () => {
    if (searchComponent.value) {
      window.scrollTo(0, searchStore.searchScrollPos || 0)
    }
  }, {once: true}
)

function refresh(done) {
  searchStore._fetchData(true).finally(() => {
    done()
  })
}

const componentIs = computed(() => {
  return componentMapping[searchStore.searchType]
})

const componentAttrs = computed(() => {
  return {}
})


return (_ctx: any,_cache: any) => {
  const _component_q_spinner_tail = _resolveComponent("q-spinner-tail")!
  const _component_q_infinite_scroll = _resolveComponent("q-infinite-scroll")!
  const _component_q_pull_to_refresh = _resolveComponent("q-pull-to-refresh")!

  return (_openBlock(), _createBlock(_component_q_pull_to_refresh, { onRefresh: refresh }, {
    default: _withCtx(() => [
      (componentIs.value)
        ? (_openBlock(), _createBlock(_component_q_infinite_scroll, {
            key: 0,
            offset: 800,
            disable: !_unref(searchStore).hasMorePages,
            onLoad: onLoad
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(componentIs.value), _mergeProps({
                ref_key: "searchComponent",
                ref: searchComponent,
                class: "c-content"
              }, componentAttrs.value), null, 16)),
              (_unref(searchStore).searchLoading || _unref(searchStore).hasMorePages)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_q_spinner_tail, { size: "lg" })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disable"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})