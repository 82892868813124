
import {useDialogPluginComponent, useQuasar} from 'quasar'
import AvatarCropper from 'src/components/utils/AvatarCropper.vue';
import {defineComponent, ref} from 'vue';
import Auth from 'src/api/auth';
import BusinessApi from 'src/api/business'
import FileUploaderField from 'components/qutils/FileUploaderField.vue';
import {toBase64} from 'src/etc/utils';


export default defineComponent({
  components: {FileUploaderField, AvatarCropper},

  props: {
    businessSlug: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits,
  ],

  setup(props) {
    // REQUIRED; must be called inside of setup()
    const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()
    const images = ref()
    const imgBase64 = ref()
    const avatarCropper = ref()
    const isLoading = ref()
    const $q = useQuasar()

    const onChange = async function (val) {
      if (val.length > 0) {
        const file = val[0]
        const buffer = await file.arrayBuffer();
        const clone = new File([buffer], file.name, {type: file.type});

        imgBase64.value = await toBase64(clone) || ''
      }
    }

    const onSave = function () {
      if (!avatarCropper.value) return
      const imageBase64 = avatarCropper.value.cropImage()

      $q.loading.show()
      isLoading.value = true

      if (props.businessSlug) {
        BusinessApi.patchBusiness(props.businessSlug, {avatar_upload: imageBase64}).then((resp) => {
          onDialogOK(resp)
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          isLoading.value = false
          $q.loading.hide()
        })
      } else {
        Auth.updateAccount({avatar_upload: imageBase64}).then((resp) => {
          onDialogOK(resp)
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          isLoading.value = false
          $q.loading.hide()
        })
      }

    }

    return {
      images,
      onChange,
      imgBase64,
      avatarCropper,
      onSave,
      isLoading,

      dialogRef,
      onDialogHide,
      onOKClick() {
        onDialogOK()
      },
      onCancelClick: onDialogCancel
    }
  }
})
