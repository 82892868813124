import ApiInput from './ApiInput.vue'
import ApiSelect from './ApiSelect.vue';
import ApiCheckbox from './ApiCheckbox.vue';
import ApiPasswordInput from 'components/qutils/form/ApiPasswordInput.vue';


export const fieldComponentMapping = {
  'field': ApiInput,
  'boolean': ApiCheckbox,
  'string': ApiInput,
  'url': ApiInput,
  'email': ApiInput,
  'regex': undefined,
  'slug': ApiInput,
  'integer': ApiInput,
  'float': ApiInput,
  'decimal': ApiInput,
  'date': ApiInput,
  'datetime': ApiInput,
  'time': undefined,
  'choice': ApiSelect,
  'multiple choice': ApiSelect,
  'file upload': undefined,
  'image upload': undefined,
  'list': undefined,
  'nested object': undefined,
  'input': 'input',
  'password': ApiPasswordInput,
}
export const fieldTypeMapping = {
  'hidden': 'hidden',
  'field': undefined,
  'boolean': undefined,
  'string': 'text',
  'url': 'url',
  'email': 'email',
  'regex': undefined,
  'slug': 'text',
  'integer': undefined,
  'float': undefined,
  'decimal': undefined,
  'date': undefined,
  'datetime': undefined,
  'time': 'time',
  'choice': undefined,
  'multiple choice': undefined,
  'file upload': undefined,
  'image upload': undefined,
  'list': undefined,
  'nested object': undefined,
  'password': 'password',
}
