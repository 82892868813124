<template>
  <q-input
    ref="passwordInput"
    :error="!!errors ? true : undefined"
    :error-message="errors ? errors.join(' ') : undefined"
    v-bind="$attrs"
    :type="showPw ? 'text' : 'password'"
  >
    <template #append>
      <q-icon
        :name="!showPw ? 'visibility_off' : 'visibility'"
        class="cursor-pointer"
        @click="showPw = !showPw"
      />
    </template>
  </q-input>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>
<script setup>
import {ref} from 'vue';

const showPw = ref(false)
const passwordInput = ref(null)

defineProps({
  errors: {default: undefined}
})
</script>
