<template>
  <q-img
    v-bind="$attrs"
    :srcset="srcSetInline"
    :sizes="sizes"
    :src="src"
  >
    <template #default>
      <template v-if="!srcset && !src && showPlaceholder">
        <slot name="placeholder">
          <div class="bg-grey-4 absolute-full">
            <div class="absolute-center text-grey-6 text-center">
              <q-icon name="image" size="md"/>
              <div class="text-xsmall">Kein Bild</div>
            </div>
          </div>
        </slot>
      </template>
      <slot></slot>
    </template>
  </q-img>
</template>

<script>

export default {
  name: 'SrcsetImg',
  props: {
    src: {required: true},
    srcset: {required: false},
    showPlaceholder: {
      default: false,
      type: Boolean
    },
  },
  computed: {
    srcSetInline() {
      if (!this.srcset || !Array.isArray(this.srcset)) {
        return
      }
      return this.srcset.flatMap(obj => `${obj.src} ${obj.size[0]}w`).join(', ')
    },
    sortedSrcset() {
      if (!this.srcset || !Array.isArray(this.srcset)) {
        return;
      }
      // Create a copy of the srcset array and sort it
      return [...this.srcset].sort((a, b) => a.size[0] - b.size[0]);
    },
    sizes() {
      if (!this.sortedSrcset) {
        return;
      }
      // Use the sortedSrcset computed property
      return this.sortedSrcset.map((img, index) => {
        if (index < this.sortedSrcset.length - 1) {
          return `(max-width: ${img.size[0]}px) ${img.size[0]}px`;
        } else {
          return `${img.size[0]}px`;
        }
      }).join(', ');
    }

  }

}
</script>
