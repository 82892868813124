import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "header-wrapper relative-position overflow-hidden",
  style: {"height":"100svh"}
}
const _hoisted_2 = {
  class: "header-background-wrapper absolute-full",
  style: {"z-index":"-2"}
}
const _hoisted_3 = {
  key: 0,
  class: "absolute-full"
}
const _hoisted_4 = { class: "flex column no-wrap full-height q-px-md" }
const _hoisted_5 = {
  style: {"z-index":"20"},
  class: "text-white"
}
const _hoisted_6 = { style: {"flex-grow":"1","margin":"auto"} }
const _hoisted_7 = { class: "col-12 col-md-6" }
const _hoisted_8 = { class: "flex column justify-center items-center" }
const _hoisted_9 = { class: "q-pt-sm" }
const _hoisted_10 = { class: "q-pt-sm" }
const _hoisted_11 = { class: "col-12 col-md-6 text-center" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "q-my-lg" }
const _hoisted_14 = {
  class: "flex items-center justify-center",
  style: {"gap":"8px"}
}
const _hoisted_15 = ["href"]
const _hoisted_16 = ["src"]
const _hoisted_17 = ["href"]
const _hoisted_18 = ["src"]
const _hoisted_19 = { style: {"opacity":"0.75"} }
const _hoisted_20 = {
  ref: "external noopener nofollow",
  target: "_blank",
  href: "https://www.reimo.com/?etcc_cmp=Campertrader&etcc_med=Banner",
  style: {"text-decoration":"none"}
}
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "container q-pb-xl q-my-xl" }
const _hoisted_23 = { class: "row q-col-gutter-x-xl q-col-gutter-y-md" }
const _hoisted_24 = { class: "col-12 col-sm-6" }
const _hoisted_25 = { class: "full-width flex items-center q-my-md" }
const _hoisted_26 = { class: "q-mt-lg" }
const _hoisted_27 = { class: "col-12 col-sm-6" }
const _hoisted_28 = { class: "c-fancy-background flex full-width q-py-lg q-px-sm full-height rounded-borders items-center justify-center" }
const _hoisted_29 = { class: "bg-secondary q-py-xl text-white" }
const _hoisted_30 = { class: "container q-my-xl" }
const _hoisted_31 = { class: "row q-col-gutter-xl" }
const _hoisted_32 = { class: "col-12 col-sm-6" }
const _hoisted_33 = { class: "flex column items-center" }
const _hoisted_34 = { class: "full-width q-mt-lg" }
const _hoisted_35 = { class: "bg-white" }
const _hoisted_36 = { class: "container q-py-xl" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-12 col-md-6 font-inter" }
const _hoisted_39 = {
  class: "q-pl-md text-left",
  style: {"list-style-type":"none"}
}
const _hoisted_40 = {
  class: "flex no-wrap q-mb-lg",
  style: {"column-gap":"8px"}
}
const _hoisted_41 = {
  class: "flex no-wrap q-mb-lg",
  style: {"column-gap":"8px"}
}
const _hoisted_42 = {
  class: "flex no-wrap",
  style: {"column-gap":"8px"}
}
const _hoisted_43 = { class: "text-small" }
const _hoisted_44 = { class: "col-12 col-md-6" }
const _hoisted_45 = { class: "flex column full-height justify-center" }
const _hoisted_46 = { class: "text-center" }
const _hoisted_47 = { class: "q-py-xl" }
const _hoisted_48 = { class: "q-my-xl" }
const _hoisted_49 = { class: "container q-mb-lg" }
const _hoisted_50 = { class: "q-py-xl bg-secondary" }
const _hoisted_51 = { class: "bg-dark q-py-xl" }
const _hoisted_52 = { class: "container q-py-xl" }
const _hoisted_53 = { class: "flex q-gutter-x-md q-gutter-y-lg justify-center" }
const _hoisted_54 = { key: 0 }
const _hoisted_55 = ["src"]
const _hoisted_56 = ["src"]
const _hoisted_57 = { class: "container q-py-xl q-mb-xl" }
const _hoisted_58 = { class: "row q-col-gutter-md" }
const _hoisted_59 = { class: "container q-mb-xl" }
const _hoisted_60 = { class: "row q-col-gutter-md" }
const _hoisted_61 = ["href", "onClick"]

import {ref} from 'vue';
import {Advert} from 'src/models/advert';
import {CustomPagination} from 'src/models/qutils/etc';
import {Cookies, useMeta} from 'quasar';
import {getPageUrl} from 'src/etc/helper';
import {useRouter} from 'vue-router';
import UserListWidget from 'src/components/etc/UserListWidget.vue';
import TestimonialWidget from 'src/components/etc/TestimonialWidget.vue';
import PartnerCarousel from 'src/components/partners/PartnerCarousel.vue';
import BlogFeed from 'components/BlogFeed.vue';
import IndexExtrasSliderNoText from 'components/etc/IndexExtrasSliderNoText.vue';
import GoogleReviewWidget from 'src/etc/components/GoogleReviewWidget.vue';
import {goToApp} from 'src/apps/core/utils';
import {APPLE_STORE_URL, GOOGLE_PLAY_URL} from 'src/apps/core/static';
import HeaderToolbar from 'components/HeaderToolbar.vue';


export interface AdvertList extends Omit<CustomPagination, 'results'> {
  results: Array<Advert>;
}


import {useMainStore} from 'stores/main';

export const radiusOptions = [5, 10, 20, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];
const __default__ = {
  async preFetch({store}) {
    const mainStore = useMainStore(store)
    await mainStore.getGoogleReviews()
    return await mainStore.fetchCities()
  },
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'IndexPageOld2',
  setup(__props) {

const mainStore = useMainStore();
const partner = ref([]);
const router = useRouter();

function clickSearch() {
  Cookies.remove('navigatedToStart')
}

const clickExtra = function (extra) {
  mainStore.setFilter({
    ...mainStore.filter,
    vehicle__extras__slug: [extra.value]
  })
  router.push({name: 'search', query: {openExtras: 'true'}})
}

useMeta(() => {
  return {
    titleTemplate(title) {
      return `Camper gebraucht kaufen | ${title}`;
    },

    meta: {
      description: {
        name: 'description',
        content:
          'Camper gebraucht kaufen und verkaufen. ' +
          'Viele gebrauchte günstige Camper Angebote für dein Vanlife Abenteuer.' +
          ' Filtere nach Solar, Toilette, Dusche und mehr.',
      },
    },

    link: {
      canonical: {
        rel: 'canonical',
        href: getPageUrl('home', router) || '',
      },
    },
  };
});


return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_img, {
            draggable: false,
            src: require('src/assets/pages/home/hero_image.webp'),
            alt: "Camper Trader background",
            class: "full-height",
            fetchpriority: "high",
            fit: "cover",
            "no-spinner": "",
            "no-transition": "",
            style: {"filter":"blur(3px)"}
          }, null, 8, ["src"])
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "absolute-full",
          style: {"z-index":"-1","background":"rgba(0, 0, 0, 0.5)"}
        }, null, -1)),
        (_ctx.$q.screen.width > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("header", _hoisted_5, [
                    _createElementVNode("div", {
                      class: _normalizeClass({'container': _ctx.$q.screen.gt.sm})
                    }, [
                      _createVNode(HeaderToolbar)
                    ], 2)
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["row justify-center full-height q-col-gutter-x-xl", _ctx.$q.screen.gt.sm ? 'items-center' : 'items-end'])
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", null, [
                          _cache[4] || (_cache[4] = _createElementVNode("h1", {
                            class: "text-white text-left text-uppercase font-inter-bold c-header-text",
                            style: {"line-height":"3.3rem"}
                          }, [
                            _createTextVNode("Dein "),
                            _createElementVNode("br"),
                            _createElementVNode("span", { class: "text-secondary" }, "Marktplatz"),
                            _createElementVNode("br"),
                            _createTextVNode("für "),
                            _createElementVNode("span", { class: "text-secondary" }, "Camper")
                          ], -1)),
                          _createElementVNode("div", {
                            class: "full-width",
                            style: _normalizeStyle({'padding-top': _ctx.$q.screen.lt.sm ? '4rem' : '2rem'})
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_q_btn, {
                                "no-caps": "",
                                class: "full-width",
                                size: "lg",
                                rounded: "",
                                color: "secondary",
                                "text-color": "dark",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goToApp)(_ctx.$q)))
                              }, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                  _createTextVNode(" App herunterladen ")
                                ])),
                                _: 1
                              })
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_q_btn, {
                                to: {name: 'search'},
                                "no-caps": "",
                                class: "full-width",
                                size: "lg",
                                rounded: "",
                                color: "white",
                                "text-color": "dark",
                                onClick: clickSearch
                              }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode(" Zur Suche ")
                                ])),
                                _: 1
                              })
                            ])
                          ], 4)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("img", {
                        class: "gt-sm",
                        style: {"max-height":"60dvh","max-width":"100%"},
                        alt: "Iphone phone with Camper Trader app",
                        src: require('src/assets/pages/home/ios_screen.webp')
                      }, null, 8, _hoisted_12),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("a", {
                            href: _unref(GOOGLE_PLAY_URL),
                            target: "_blank",
                            style: {"text-decoration":"none"}
                          }, [
                            _createElementVNode("img", {
                              alt: "Download Google Play Store",
                              height: "45px",
                              src: require('src/assets/storeBtns/google-play-badge_200.png')
                            }, null, 8, _hoisted_16)
                          ], 8, _hoisted_15),
                          _createElementVNode("a", {
                            href: _unref(APPLE_STORE_URL),
                            target: "_blank",
                            style: {"text-decoration":"none"}
                          }, [
                            _createElementVNode("img", {
                              alt: "Download Apple Store",
                              height: "45px",
                              src: require('src/assets/storeBtns/apple.svg')
                            }, null, 8, _hoisted_18)
                          ], 8, _hoisted_17)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("a", _hoisted_20, [
                          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-white font-inter" }, "Unser Partner", -1)),
                          _createElementVNode("img", {
                            alt: "Reimo",
                            height: "30px",
                            src: require('src/assets/logos/Reimo_Logo_weiss_600.png')
                          }, null, 8, _hoisted_21)
                        ], 512)
                      ])
                    ])
                  ], 2)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "text-h1 q-mb-md font-inter-bold" }, "Einzigartige Filter", -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-h2 q-mb-sm font-inter" }, "Genau nach deinen Bedürfnissen!", -1)),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "q-mb-xs text-muted font-inter" }, "Kein stundenlanges Suchen. Sofort zum Traum-Camper.", -1)),
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_q_btn, {
                      to: {name: 'search'},
                      dark: "",
                      color: "dark",
                      push: "",
                      size: "lg",
                      class: "q-px-xl font-inter-bold",
                      rounded: "",
                      "no-caps": ""
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Finde deinen Traum-Camper ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createVNode(IndexExtrasSliderNoText, {
                  size: "1.5rem",
                  style: {"max-width":"100%"}
                })
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(UserListWidget, { "label-class": "text-white text-h5 q-mt-sm" })
                  ]),
                  _: 1
                }))
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["col-12 col-sm-6 flex items-center", {'text-center': _ctx.$q.screen.lt.sm}])
            }, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "full-width" }, [
                _createElementVNode("h2", { class: "q-mb-md text-h1 font-inter-bold" }, "Mach mit!"),
                _createElementVNode("h3", { class: "q-mb-md" }, "Bei Deutschlands größtem Campervan Marktplatz"),
                _createElementVNode("div", null, "Werde Teil unserer einzigartigen Community, ein Zusammenschluss Gleichgesinnter.")
              ], -1)),
              _createElementVNode("div", _hoisted_34, [
                _createVNode(_component_q_btn, {
                  size: "lg",
                  rounded: "",
                  "no-caps": "",
                  class: "font-inter-bold q-px-xl",
                  to: { name: 'login' },
                  color: "white",
                  "text-color": "dark",
                  push: ""
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Jetzt kostenlos anmelden ")
                  ])),
                  _: 1
                })
              ])
            ], 2)
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, [
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("div", _hoisted_38, [
              _cache[24] || (_cache[24] = _createElementVNode("h2", { class: "text-h1 font-inter-bold q-mb-md" }, " Verkaufe kostenlos deinen Camper ", -1)),
              _cache[25] || (_cache[25] = _createElementVNode("p", { class: "font-inter q-mb-md" }, [
                _createElementVNode("b", null, "Du möchtest deinen Camper verkaufen?"),
                _createTextVNode(" Dann bist du bei uns genau richtig! Wir bieten dir eine Plattform, auf der du deinen Camper schnell und unkompliziert verkaufen kannst. ")
              ], -1)),
              _createElementVNode("ul", _hoisted_39, [
                _createElementVNode("li", _hoisted_40, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_icon, {
                      name: "fas fa-check",
                      size: "md"
                    })
                  ]),
                  _cache[13] || (_cache[13] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h4 q-mb-sm" }, " Keine versteckten Kosten, Provisionen oder Gebühren "),
                    _createElementVNode("div", { class: "text-small" }, " Ein Inserat ist für private Anbieter 100% kostenlos, ab zwei Inseraten bieten wir Paket-Optionen an. ")
                  ], -1))
                ]),
                _createElementVNode("li", _hoisted_41, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_icon, {
                      name: "fas fa-check",
                      size: "md"
                    })
                  ]),
                  _cache[14] || (_cache[14] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "text-h4 q-mb-sm" }, " Unkompliziert "),
                    _createElementVNode("div", { class: "text-small" }, " Erstelle dein Inserat in unter fünf Minuten. Wir unterstützen dich auch sehr gerne dabei deinen Camper zu verkaufen! ")
                  ], -1))
                ]),
                _createElementVNode("li", _hoisted_42, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_icon, {
                      name: "fas fa-check",
                      size: "md"
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-h4 q-mb-sm" }, "Eine große Community", -1)),
                    _createElementVNode("div", _hoisted_43, [
                      _cache[16] || (_cache[16] = _createTextVNode(" Werde Teil einer wachsenden Community von Camping-Fans. ")),
                      _createVNode(_component_router_link, { to: { name: 'login' } }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("Registriere dich jetzt! ")
                        ])),
                        _: 1
                      })
                    ])
                  ])
                ])
              ]),
              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "q-mt-lg" }, [
                _createElementVNode("strong", null, "Willst du mehr über Camper Trader erfahren?")
              ], -1)),
              _createElementVNode("p", null, [
                _createVNode(_component_router_link, { to: { name: 'about-us' } }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("Hier erfährst du mehr ")
                  ])),
                  _: 1
                }),
                _cache[21] || (_cache[21] = _createTextVNode(" über uns, unsere ")),
                _createVNode(_component_router_link, { to: { name: 'faq-help' } }, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode("FAQs")
                  ])),
                  _: 1
                }),
                _cache[22] || (_cache[22] = _createTextVNode(" oder ")),
                _createVNode(_component_router_link, { to: { name: 'contact' } }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("kontaktiere uns")
                  ])),
                  _: 1
                }),
                _cache[23] || (_cache[23] = _createTextVNode(" . "))
              ])
            ]),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _createVNode(_component_q_img, {
                  src: require('src/assets/illustration_van.png'),
                  alt: "van illustration"
                }, null, 8, ["src"]),
                _createElementVNode("div", _hoisted_46, [
                  _createVNode(_component_q_btn, {
                    size: "lg",
                    to: { name: 'createAdvert' },
                    class: "font-inter-bold q-px-xl",
                    color: "secondary",
                    "no-caps": "",
                    push: "",
                    rounded: ""
                  }, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode(" Inserat kostenlos erstellen ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_q_separator),
      _withDirectives(_createElementVNode("section", _hoisted_47, [
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("h2", {
              class: _normalizeClass([{container: _ctx.$q.screen.lt.sm}, "font-inter-bold text-dark"])
            }, " Partner die uns vertrauen ", 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({container: !_ctx.$q.screen.lt.sm})
          }, [
            _createVNode(PartnerCarousel, {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = val => partner.value = val)
            })
          ], 2)
        ])
      ], 512), [
        [_vShow, partner.value?.length]
      ]),
      _createVNode(_component_q_separator),
      _createVNode(BlogFeed),
      _createElementVNode("section", _hoisted_50, [
        _createVNode(TestimonialWidget, { class: "q-py-xl" })
      ]),
      _createElementVNode("section", _hoisted_51, [
        _createElementVNode("div", _hoisted_52, [
          _createElementVNode("div", _hoisted_53, [
            (Object.keys(_unref(mainStore).googleReviewsResponse).length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                  _createVNode(_component_q_card, { class: "full-height" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, { class: "q-py-sm full-height flex items-center" }, {
                        default: _withCtx(() => [
                          _createVNode(GoogleReviewWidget, {
                            rating: _unref(mainStore).googleReviewsResponse?.rating,
                            "user-ratings-total": _unref(mainStore).googleReviewsResponse?.user_ratings_total
                          }, null, 8, ["rating", "user-ratings-total"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_q_card, { class: "full-height" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { class: "q-py-sm full-height flex items-center" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "full-width",
                        style: {"height":"100px"},
                        alt: "Lets encrypt logo",
                        src: require('src/assets/certs/le-logo-standard.svg')
                      }, null, 8, _hoisted_55)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_q_card, { class: "full-height" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { class: "q-py-sm full-height flex items-center" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        alt: "Trustpilot logo",
                        class: "full-width",
                        src: require('src/assets/certs/TP-Logo-Tricolor-Black-RGB.svg')
                      }, null, 8, _hoisted_56)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _createVNode(_component_q_separator),
      _createElementVNode("section", _hoisted_57, [
        _createElementVNode("div", _hoisted_58, [
          _cache[28] || (_cache[28] = _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("h4", null, "Beliebte Städte für gebrauchte Camper")
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mainStore).cities, (city) => {
            return (_openBlock(), _createElementBlock("div", {
              key: city.name,
              class: "col-12 col-sm-6 col-md-4"
            }, [
              _createVNode(_component_router_link, {
                to: `/suche/umkreis-${city.name.toLowerCase()}/`
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Camper kaufen in " + _toDisplayString(city.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("section", _hoisted_59, [
        _createElementVNode("div", _hoisted_60, [
          _cache[29] || (_cache[29] = _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("h4", null, "Nach Camper Ausstattung")
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mainStore).vehicleExtras, (extra) => {
            return (_openBlock(), _createElementBlock("div", {
              key: extra.value,
              class: "col-12 col-sm-6 col-md-4"
            }, [
              _createElementVNode("a", {
                href: `/suche?campervan=${extra.name}`,
                onClick: _withModifiers(($event: any) => (clickExtra(extra)), ["prevent"])
              }, " Camper mit \"" + _toDisplayString(extra.name) + "\" kaufen ", 9, _hoisted_61)
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}
}

})