import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "container row q-col-gutter-y-xl q-my-xl" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "text-center text-h4" }
const _hoisted_4 = {
  key: 0,
  class: "col-12"
}
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = {
  key: 1,
  class: "col-12"
}
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = {
  key: 2,
  class: "col-12"
}
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = {
  key: 3,
  class: "col-12"
}

import ShimmerText from 'src/components/ShimmerText.vue';
import {runSequentialPromises, useMeta} from 'quasar';
import Partner from 'src/api/partner'
import {Partner as PartnerModel} from 'src/models/partners.model'
import ListPartner from 'src/components/partners/ListPartner.vue';
import {ref} from 'vue';
import {getPageUrl} from 'src/etc/helper';
import {useRouter} from 'vue-router';
import SecureEmail from 'components/qutils/SecureEmail.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerPage',
  setup(__props) {


const platinPartner = ref<Array<PartnerModel> | undefined>()
const goldPartner = ref<Array<PartnerModel> | undefined>()
const silverPartner = ref<Array<PartnerModel> | undefined>()
const cooperationPartner = ref<Array<PartnerModel> | undefined>()
const otherPartner = ref<Array<PartnerModel> | undefined>()
const router = useRouter()

runSequentialPromises({
  platin: () => Partner.getPartnerList({rank: 'platin', is_partner_site: true}),
  gold: () => Partner.getPartnerList({rank: 'gold', is_partner_site: true}),
  silver: () => Partner.getPartnerList({rank: 'silver', is_partner_site: true}),
  cooperation: () => Partner.getPartnerList({rank: 'cooperation', is_partner_site: true}),
  other: () => Partner.getPartnerList({rank: 'other', is_partner_site: true})
}).then(resultAggregator => {
  // resultAggregator is ordered in the same way as the promises above
  platinPartner.value = ((resultAggregator.platin as any).value.data)
  goldPartner.value = ((resultAggregator.gold as any).value.data)
  silverPartner.value = ((resultAggregator.silver as any).value.data)
  cooperationPartner.value = ((resultAggregator.cooperation as any).value.data)
  otherPartner.value = ((resultAggregator.other as any).value.data)
}).catch(errResult => {
  console.error(`Error encountered while fetching ${errResult.key}:`)
  console.error(errResult.reason)
  console.debug('Managed to get these results before this error:')
})

useMeta(() => {
  return {
    // sets document title
    titleTemplate(title) {
      return `Unsere Partner | ${title}`
    },

    link: {
      canonical: {
        rel: 'canonical', href: getPageUrl('partner', router) || ''
      }
    },

    // meta tags
    meta: {
      description: {
        name: 'description', content: 'Erfahre mehr über unsere Partner und Kooperationen oder kontaktiere uns um ' +
          'selber Partner oder Unterstützer zu werden.'
      },
      keywords: {
        name: 'keywords',
        content: 'Partner werden, Kooperation, Wohnmobile kaufen, Wohnwagen kaufen, Camper kaufen, ' +
          'Gebrauchte Wohnmobile, Gebrauchte Wohnwagen, Gebrauchte Campervans, Privat verkaufen, Privat kaufen, ' +
          'Campermarkt, Gebrauchte Camper kaufen, Wohnmobilmarkt, Wohnwagenmarkt, Wohnmobile von privat, ' +
          'Wohnwagen von privat, Camper von privat, Campingfahrzeuge, Camper inserieren, Wohnmobile inserieren, ' +
          'Wohnwagen inserieren, Camper Trader Deutschland'
      },
    },


  }
})


return (_ctx: any,_cache: any) => {
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h1", {
            class: "text-h3 text-center font-fjalla-one",
            style: {"font-size":"2rem","line-height":"2rem"}
          }, " Interesse an einer Partnerschaft und/oder Kooperation? ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createTextVNode(" Anfragen an ")),
            _createVNode(SecureEmail, { email: "partner@campertrader.de" })
          ])
        ]),
        (platinPartner.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h1", _hoisted_5, [
                _createVNode(ShimmerText, {
                  class: "font-inter-bold text-uppercase",
                  color: "#a0b2c6"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Platinpartner")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(ListPartner, {
                "model-value": platinPartner.value,
                height: "300px",
                width: "300px"
              }, null, 8, ["model-value"])
            ]))
          : _createCommentVNode("", true),
        (goldPartner.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("h1", _hoisted_7, [
                _createVNode(ShimmerText, {
                  class: "font-inter-bold text-uppercase",
                  color: "#d4af37"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Goldpartner")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(ListPartner, {
                "model-value": goldPartner.value,
                height: "150px",
                width: "150px"
              }, null, 8, ["model-value"])
            ]))
          : _createCommentVNode("", true),
        (silverPartner.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("h2", _hoisted_9, [
                _createVNode(ShimmerText, { class: "font-inter-bold text-uppercase" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Silberpartner")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(ListPartner, {
                "model-value": silverPartner.value,
                height: "125px",
                width: "125px"
              }, null, 8, ["model-value"])
            ]))
          : _createCommentVNode("", true),
        (cooperationPartner.value?.length || otherPartner.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-center font-inter-bold text-uppercase" }, " Unterstützer ", -1)),
              (cooperationPartner.value?.length)
                ? (_openBlock(), _createBlock(ListPartner, {
                    key: 0,
                    "model-value": cooperationPartner.value,
                    class: "q-mb-md",
                    height: "75px",
                    width: "75px"
                  }, null, 8, ["model-value"]))
                : _createCommentVNode("", true),
              (otherPartner.value?.length)
                ? (_openBlock(), _createBlock(ListPartner, {
                    key: 1,
                    "model-value": otherPartner.value,
                    height: "75px",
                    width: "75px"
                  }, null, 8, ["model-value"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})