import script from "./SearchPage.vue?vue&type=script&setup=true&lang=ts"
export * from "./SearchPage.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import qInstall from "../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QFooter});
