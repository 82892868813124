import {api} from 'boot/axios';

export default {
  getPartnerList: function (params: object | undefined = undefined) {
    return api.get('partner/', {params})
  },
  getPartnerDetail: function (uuid) {
    return api.get(`partner/${uuid}/`,)
  }
}
