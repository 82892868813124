<template>
  <q-dialog
    ref="dialogRef"
    transition-show="slide-up"
    transition-hide="slide-down"
    position="bottom"
    full-height
    no-route-dismiss
    :full-width="$q.screen.lt.sm"
  >
    <q-card class="q-dialog-plugin c-dialog">
      <div class="flex column no-wrap full-height">
        <dialog-header></dialog-header>
        <div class="scroll-y" style="flex: 1">
          <search-filter-wrapper
            :search-type="searchStore.searchType"
            :model-value="modelValue"
            @update:model-value="(val) => emit('update:modelValue', val)"
          />
        </div>

        <q-separator/>
        <q-card-section>
          <div class="flex justify-between no-wrap">
            <q-btn flat no-caps @click="searchStore.resetFilterData()">Zurücksetzen</q-btn>
            <q-space></q-space>

            <div class="flex no-wrap items-center q-gutter-x-sm text-small">
              <div>
                <template v-if="!searchStore.searchLoading">{{ searchStore.searchResultCount }} Ergebnisse</template>
              </div>
              <q-btn unelevated no-caps color="primary" @click="onDialogOK">
                Anzeigen
              </q-btn>
            </div>
          </div>
        </q-card-section>

      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import SearchFilterWrapper from 'src/apps/search/components/SearchFilterWrapper.vue';
import DialogHeader from 'src/apps/core/components/DialogHeader.vue';
import {useSearchStore} from 'src/apps/search/store';
import {onBeforeRouteUpdate, useRoute} from 'vue-router';
import {watch} from 'vue';

defineProps({
  modelValue: {required: true},
  searchResultCount: {required: false, type: Number},
})

const route = useRoute()

const emit = defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  'update:modelValue',
  ...useDialogPluginComponent.emits
])

const {dialogRef, onDialogOK, onDialogHide} = useDialogPluginComponent()

watch(
  () => route.name,
  (toName, fromName) => {
    // Check if the specific parameter you care about has changed
    if (toName !== fromName) {
      onDialogHide()
    }
  },
)

onBeforeRouteUpdate((to, from, next) => {
  if (to.name !== 'search') {
    onDialogHide()
  }
  next()
})

const searchStore = useSearchStore()
// dialogRef      - Vue ref to be applied to QDialog
// onDialogHide   - Function to be used as handler for @hide on QDialog
// onDialogOK     - Function to call to settle dialog with "ok" outcome
//                    example: onDialogOK() - no payload
//                    example: onDialogOK({ /*...*/ }) - with payload
// onDialogCancel - Function to call to settle dialog with "cancel" outcome

// this is part of our example (so not required)
// function onOKClick() {
//   // on OK, it is REQUIRED to
//   // call onDialogOK (with optional payload)
//   onDialogOK()
//   // or with payload: onDialogOK({ ... })
//   // ...and it will also hide the dialog automatically
// }
</script>
<style scoped lang="scss">
.c-dialog {
  height: 97dvh !important;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
