import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-detail-header-gallery" }
const _hoisted_2 = {
  key: 0,
  class: "bg-grey-4 absolute-full"
}
const _hoisted_3 = { class: "absolute-center text-grey-5 text-center" }
const _hoisted_4 = {
  class: "absolute-bottom-right",
  style: {"z-index":"1"}
}
const _hoisted_5 = {
  class: "text-white q-px-sm q-mb-md q-mr-md rounded-borders text-bold",
  style: {"background-color":"rgba(0, 0, 0, 0.5)"}
}
const _hoisted_6 = {
  key: 0,
  class: "relative-position c-gallery-wrapper rounded-borders overflow-hidden"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "bg-grey-4 rounded-borders full-width full-height"
}
const _hoisted_9 = { class: "absolute-center text-grey-5 text-center" }
const _hoisted_10 = {
  key: 0,
  class: "q-ma-md absolute",
  style: {"bottom":"0","right":"0"}
}

import {computed, PropType, ref, watch} from 'vue';

interface Image {
  path: string;
  alt?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailHeaderGallery',
  props: {
  images: {type: Array<Image | any>, required: true, default: []},
  aspectRatio: {type: Number, default: 4 / 3},
  galleryType: {type: String as PropType<'advert' | 'item'>, default: 'advert'},
},
  emits: ['openGallery'],
  setup(__props) {

const props = __props




const slide = ref(0)
const swiperRef = ref<HTMLElement | any | null>(null)


watch(
  () => swiperRef.value,
  () => {
    if (swiperRef.value && swiperRef.value?.swiper) {
      swiperRef.value.swiper.on('activeIndexChange', (swiper) => {
        slide.value = swiper.activeIndex
      })
    } else {
      console.warn('swiperRef not found')
    }
  },
  {once: true}
)

const imageStyle = computed(() => {
  if (props.galleryType === 'item') {
    return {}
  }

  return {
    'grid-column': 'span 2',
    'grid-row': 'span 2'
  }
})

const gridTemplate = computed((): any => {
  // monkey dirty way to fix the grid for item gallery
  if (props.galleryType === 'item') return gridTemplateItem.value

  let styling, splice
  if (props.images.length >= 5) {
    styling = {
      'grid-template-columns': 'repeat(4, 1fr)',
      'grid-template-rows': 'repeat(2, 1fr)',
    }
    splice = 5
  } else if (props.images.length >= 3) {
    styling = {
      'grid-template-columns': 'repeat(3, 1fr)',
      'grid-template-rows': 'repeat(2, 1fr)',
    }
    splice = 3
  } else if (props.images.length == 2) {
    styling = {
      'grid-template-columns': 'repeat(2, 1fr)',
      'grid-template-rows': 'repeat(1, 1fr)',
    }
    splice = 2
  } else {
    styling = {
      'grid-template-columns': 'repeat(1, 1fr)',
      'grid-template-rows': 'repeat(1, 1fr)',
    }
    splice = 1
  }

  return {
    styling,
    splice,
  }
});

const gridTemplateItem = computed(() => {
  let styling, splice
  if (props.images.length >= 3) {
    styling = {
      'grid-template-columns': 'repeat(3, 1fr)',
      'grid-template-rows': 'repeat(1, 1fr)',
    }
    splice = 3
  } else if (props.images.length == 2) {
    styling = {
      'grid-template-columns': 'repeat(2, 1fr)',
      'grid-template-rows': 'repeat(1, 1fr)',
    }
    splice = 2
  } else {
    styling = {
      'grid-template-columns': 'repeat(1, 1fr)',
      'grid-template-rows': 'repeat(1, 1fr)',
    }
    splice = 1
  }

  return {
    styling,
    splice,
  }
});



return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "relative-position",
        style: _normalizeStyle({aspectRatio: __props.aspectRatio})
      }, [
        (_ctx.$q.screen.width > 0 && __props.images?.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_icon, {
                  name: "image",
                  size: "md"
                }),
                _cache[1] || (_cache[1] = _createElementVNode("div", null, "Keine Bilder", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("swiper-container", {
          ref_key: "swiperRef",
          ref: swiperRef,
          style: _normalizeStyle({ aspectRatio: __props.aspectRatio }),
          "pagination-dynamic-bullets": "true",
          "long-swipes": "true",
          "space-between": "0",
          "long-swipes-ratio": "0.25"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images, (item, index) => {
            return (_openBlock(), _createElementBlock("swiper-slide", { key: index }, [
              _createVNode(_component_q_img, {
                "no-spinner": "",
                src: item?.path,
                alt: item?.alt,
                fit: "cover",
                ratio: __props.aspectRatio,
                onClick: ($event: any) => (_ctx.$emit('openGallery', index))
              }, null, 8, ["src", "alt", "ratio", "onClick"])
            ]))
          }), 128))
        ], 4),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(__props.images?.length ? slide.value + 1 : 0) + " / " + _toDisplayString(__props.images?.length), 1)
        ])
      ], 4)
    ], 512), [
      [_vShow, _ctx.$q.screen.lt.sm]
    ]),
    (!_ctx.$q.screen.lt.sm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "gallery",
            style: _normalizeStyle(gridTemplate.value.styling)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images.slice(0, gridTemplate.value.splice), (image, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "photo bg-grey-4",
                style: _normalizeStyle(index == 0 && __props.images.length > 2 ? imageStyle.value : undefined),
                onClick: ($event: any) => (_ctx.$emit('openGallery', index))
              }, [
                _createVNode(_component_q_img, {
                  "no-spinner": "",
                  width: "100%",
                  height: "100%",
                  fit: __props.images?.length > 1 ? 'cover' : 'contain',
                  src: image.path,
                  alt: image?.alt
                }, null, 8, ["fit", "src", "alt"])
              ], 12, _hoisted_7))
            }), 128)),
            (__props.images?.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_q_icon, {
                      name: "image",
                      size: "md"
                    }),
                    _cache[2] || (_cache[2] = _createElementVNode("div", null, "Keine Bilder", -1))
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 4),
          (__props.images?.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_q_btn, {
                  icon: "fas fa-images",
                  class: "",
                  rounded: "",
                  color: "primary",
                  "no-caps": "",
                  label: "Alle Bilder anzeigen",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openGallery', 0)))
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})