import {Loading, Notify, useQuasar} from 'quasar';
import {Advert} from 'src/models/advert';
import Adverts from 'src/api/adverts';
import SelectReasonModal from 'src/components/modals/SelectReasonModal.vue';

export default function useAdvertUtils(emit: any = undefined) {
  const $q = useQuasar();

  const deleteAdvert = async (advert: Advert) => {
    return new Promise(async (resolve, reject) => {
      const resp = await Adverts.getReasonChoices()
      const name = `${advert.vehicle.brand_display} ${advert.vehicle.model}`
      $q.dialog({
        component: SelectReasonModal,
        componentProps: {
          options: resp.data,
          title: `Inserat "${name}" löschen?`,
          message: 'Bist du sicher, dass du dein Inserat löschen möchtest? Gibt es dafür einen Grund?',
          btnLabel: 'Löschen',
          btnColor: 'negative',
        },
      }).onOk(async payload => {
        try {
          await Adverts.deleteAdvert(advert.uuid, payload)
          Notify.create({
            message: `Inserat "${name}" wurde gelöscht.`
          })
          emit('changedAdvert')
          resolve(payload)
        } catch (err) {
          console.error(err)
          reject(err)
        }
      });
    })
  };

  const toggleStatus = function (advert) {
    return Adverts.setAdvertStatus(advert.uuid, !advert.active).then((resp) => {
      advert.active = !advert.active
      return resp
    })
  }

  const extendAdvert = function (advert) {
    Loading.show({
      delay: 400
    })

    return Adverts.setExtendAdvert(advert.uuid).then(resp => {
      advert.active_until = resp.data.active_until
      const activeUntil = new Date(resp.data.active_until)
      advert.has_expired = false
      Notify.create({
        icon: 'done',
        message: `Das Inserat wurde erfolgreich verlängert bis zum ${activeUntil.toLocaleDateString(['de'])}.`,
        color: 'positive'
      })
    }).finally(() => {
      Loading.hide()
    })
  }


  return {
    deleteAdvert,
    toggleStatus,
    extendAdvert,
  };
}
