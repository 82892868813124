
import {useDialogPluginComponent} from 'quasar'
import {ref, toRef} from 'vue';
import Adverts from 'src/api/adverts';
import {randomIntFromInterval} from 'src/etc/helper';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';
import Filter from 'src/etc/qutils/filter'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';

export default {
  components: {BaseDialogContent, DynamicInput},
  props: {
    modelValue: {
      required: true,
      type: Object
    },
  },

  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits,
  ],

  setup(props) {
    // REQUIRED; must be called inside of setup()
    const {dialogRef, onDialogHide} = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome
    const modelVal = toRef(props, 'modelValue')
    const filter = ref(modelVal.value)

    const optionResponse = ref()
    const colorOptions = ref()
    const fuelOptions = ref()
    const transmissionOptions = ref()
    const emissionClassOptions = ref()
    const environmentalBadgeOptions = ref()
    const loading = ref(true)

    Adverts.getAdvertOptions().then(resp => {
      optionResponse.value = resp.data.actions.POST
      colorOptions.value = optionResponse.value.vehicle.children.equipment.children.color
      fuelOptions.value = optionResponse.value.vehicle.children.fuel_type
      transmissionOptions.value = optionResponse.value.vehicle.children.transmission
      emissionClassOptions.value = optionResponse.value.vehicle.children.emission_class
      environmentalBadgeOptions.value = optionResponse.value.vehicle.children.environmental_badge
    }).finally(() => {
      loading.value = false
    })

    return {
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      // onDialogHide,

      // other methods that we used in our vue html template;
      // these are part of our example (so not required)

      filter,
      colorOptions,
      fuelOptions,
      transmissionOptions,
      emissionClassOptions,
      environmentalBadgeOptions,
      optionResponse,
      loading,
      randomIntFromInterval,
      Filter,
      onDialogHide,
    }
  }
}
