<template>
  <q-dialog ref="dialogRef" persistent @hide="onDialogHide">
    <q-card style="min-width: 30vw">
      <q-card-section>
        <q-input
          v-model="message" placeholder="Deine Nachricht"
                 type="textarea"></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn v-close-popup flat>Abbrechen</q-btn>
        <q-btn color="primary" unelevated @click="createMessageFromDialog">Absenden</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent, useQuasar} from 'quasar'
import {ref} from 'vue';
import {createMessage} from 'src/components/adverts/MessageUserButton.vue'

const message = ref()
const $q = useQuasar()

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()

const props = defineProps({
  advertUuid: {
    required: false,
    type: String,
    default: undefined,
  },
  userUuid: {
    required: true,
    type: String
  }
})

defineEmits([
  ...useDialogPluginComponent.emits
])


const createMessageFromDialog = () => {
  let messagePromise = createMessage(
    message.value,
    {advertUuid: props.advertUuid, participants: [props.userUuid]}
  )

  if (!messagePromise) return
  messagePromise.then(() => {
    onDialogOK()
    $q.notify({
      message: 'Nachricht wurde abgesendet',
      type: 'positive'
    })
  }).catch(err => {
    $q.notify({
      message: err.message,
      type: 'negative'
    })
    onDialogHide()
  })
}

</script>
