<template>
  <div>
    <q-checkbox
      v-bind="$attrs"
    >
      <slot>
        <div v-if="hint" class="text-small text-grey">{{ hint }}</div>
      </slot>
    </q-checkbox>

    <div v-if="errors" class="q-mt-sm">
      <q-banner class="text-white bg-negative" dense>{{ errors ? errors.join(' ') : undefined }}</q-banner>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ApiCheckbox',
  props: {
    errors: {default: undefined},
    hint: {required: false}
  }
}
</script>
