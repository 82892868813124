import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "full-height flex bg-white column no-wrap items-stretch c-chat-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "q-my-md full-width"
}
const _hoisted_3 = { class: "flex justify-between no-wrap items-center" }
const _hoisted_4 = { class: "col-shrink" }
const _hoisted_5 = { class: "flex items-center no-wrap q-gutter-x-sm" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "col-shrink overflow-hidden" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "row q-col-gutter-x-md" }
const _hoisted_10 = { class: "col-4 relative-position" }
const _hoisted_11 = {
  key: 0,
  class: "full-height text-uppercase full-width text-center text-bold"
}
const _hoisted_12 = { class: "absolute-center text-h4" }
const _hoisted_13 = { class: "col-8" }
const _hoisted_14 = { class: "text-bold q-mb-sm" }
const _hoisted_15 = { class: "text-grey-6" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "row q-col-gutter-x-md" }
const _hoisted_18 = { class: "col-3 relative-position" }
const _hoisted_19 = {
  key: 0,
  class: "full-height text-uppercase full-width text-center text-bold"
}
const _hoisted_20 = { class: "absolute-center text-h4" }
const _hoisted_21 = { class: "col-9" }
const _hoisted_22 = { class: "text-bold q-mb-sm" }
const _hoisted_23 = { class: "text-grey-6" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = {
  class: "relative-position",
  style: {"flex-grow":"1"}
}
const _hoisted_26 = {
  key: 0,
  class: "c-chat-messages flex column no-wrap reverse full-width"
}
const _hoisted_27 = {
  key: 1,
  class: "full-height full-width relative-position"
}
const _hoisted_28 = { class: "absolute-center text-grey-4 flex column items-center" }
const _hoisted_29 = {
  key: 0,
  class: "absolute-center"
}
const _hoisted_30 = {
  key: 1,
  class: "absolute-center text-center"
}
const _hoisted_31 = {
  key: 3,
  class: "footer__fix"
}
const _hoisted_32 = { class: "bg-grey-1 q-pa-md q-py-sm" }
const _hoisted_33 = { class: "flex q-gutter-x-xs no-wrap c-chat-input-wrapper" }
const _hoisted_34 = {
  key: 0,
  class: "text-center"
}

import {computed, onBeforeUnmount, onMounted, Ref, ref, watch} from 'vue';
import {onBeforeRouteUpdate, useRouter} from 'vue-router';
import {MessageModel} from 'src/models/message.model';
import {debounce, Notify} from 'quasar';
import Messages from 'src/api/messages';
import ProfileName from 'components/etc/ProfileName.vue';
import SrcsetImg from 'components/qutils/SrcsetImg.vue';


import {useMainStore} from 'stores/main';


const __default__ = {
  preFetch({store, currentRoute, redirect, urlPath}) {
    const mainStore = useMainStore(store)
    mainStore.userActiveChat = undefined
    let chatUuid = currentRoute.params.chatUuid?.toString()
    if (!chatUuid) {
      if (!mainStore.userChats?.length) return
      // use last chat as active
      chatUuid = mainStore.userChats[0]['uuid']
    }

    mainStore.userActiveChatMessages = []
    return mainStore.fetchChat(chatUuid).then(async () => {
      await mainStore.setActiveChat(mainStore.userChatDetails[chatUuid])
      await mainStore.fetchActiveChatMessages(chatUuid, true)
      await mainStore.fetchUnreadMessagesCount()
    }).catch(err => {
      const {response} = err
      if ([404].includes(response?.status)) {
        redirect({name: 'messenger'})
      } else if ([400].includes(response?.status)) {
        redirect({name: '404', query: {url: urlPath}})
      } else {
        console.error(err)
      }
      return err
    })
  }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'MessengerChat',
  setup(__props) {

const mainStore = useMainStore()
const chatContainer = ref<Ref | null>(null)
const autoScrollActive = ref(true)
const currentMessage = ref<string | undefined>()
const isSendingMessage = ref(false)
const loadingMessages = ref(false)
const messageInput = ref<Ref | null>(null)
const router = useRouter()

// const userUuid = computed(() => {
//   return mainStore.accountDetail?.uuid
// })
//
// const isReadOnlyInput = computed(() => {
//   return !!(isSendingMessage.value ||
//     mainStore.userActiveChat?.advert?.deleted ||
//     (mainStore.userActiveChat?.participants?.length && mainStore.userActiveChat?.participants?.length < 2))
// })

const messages = computed((): Array<MessageModel> => {
  return mainStore.userActiveChatMessages
})

const scrollToBottom = () => {
  if (!chatContainer.value) return
  if (!autoScrollActive.value) return

  let el = chatContainer.value.$el
  setTimeout(() => {
    el.scrollTop = el.scrollHeight
  }, 10)
}

watch(
  () => mainStore.userActiveChatMessages,
  () => {
    scrollToBottom()
  },
  {immediate: true}
)

onBeforeRouteUpdate((to, from, next) => {
  autoScrollActive.value = true
  next()
})


const fetchMessages = (showLoading = true) => {
  if (!mainStore.userActiveChat?.uuid) return
  loadingMessages.value = showLoading
  mainStore.fetchChat(mainStore.userActiveChat.uuid)
  mainStore.fetchActiveChatMessages(mainStore.userActiveChat.uuid).catch(err => {
    console.error(err)
    return err
  }).finally(() => {
    autoScrollActive.value = true
    loadingMessages.value = false
    scrollToBottom()
  })
}

const onChatScroll = debounce((e) => {
  let maxScrollTop = e.target.scrollHeight - e.target.offsetHeight
  autoScrollActive.value = e.target.scrollTop === maxScrollTop
}, 50)

const markRead = (chatUuid) => {
  if (!chatUuid) return
  Messages.markReadMessage(chatUuid).catch(err => {
    console.error(err)
  })
  mainStore.fetchUnreadMessagesCount()
}


let timeout, messagePolling
onMounted(() => {
  scrollToBottom()
  const btn = document.getElementById('btn-submit')
  if (btn) {
    btn.addEventListener('touchend', (e) => {
      e.preventDefault()
      createMessage()
      messageInput.value.focus()
    })
  }

  if (mainStore.userActiveChat) {
    try {
      timeout = Number.parseInt(process.env.MESSAGES_POLLING || '')
    } catch (e) {
      console.error(e)
    }
    if (timeout) {
      clearInterval(messagePolling)
      messagePolling = setInterval(() => {
        // mark current chat as read
        if (mainStore.userChatLoading) return
        fetchMessages(false)
        markRead(mainStore.userActiveChat?.uuid)
      }, timeout)
    }
  }
})

onBeforeUnmount(() => {
  if (messagePolling) {
    clearInterval(messagePolling)
  }
})

const createMessage = () => {
  messageInput.value.focus({focusVisible: true})
  if (!mainStore.userActiveChat) return
  if (!currentMessage.value || currentMessage.value === '') return

  let _msg = currentMessage.value

  currentMessage.value = ''
  isSendingMessage.value = true
  Messages.createMessage(mainStore.userActiveChat.uuid, {
    content: _msg,
  })
    .then(() => {
      mainStore.fetchMessengerUserChats()
      fetchMessages(false)
    }).catch(err => {
    console.error(err)
    currentMessage.value = _msg
    return err
  }).finally(() => {
    isSendingMessage.value = false
    messageInput.value.focus({focusVisible: true})
  })
}

const inputOnKeyDown = function (e) {
  if (isSendingMessage.value) return
  if (['NumpadEnter', 'Enter'].includes(e.code) && e.ctrlKey) {
    createMessage()
    return
  }
}

const getChatReceiver = function (participants) {
  if (!mainStore.accountDetail?.uuid) return participants
  return participants.filter(obj => !obj.is_current_account)
}

const deactivateChat = function (chatUuid) {
  Messages.deactivateChat(chatUuid).then(() => {
    mainStore.fetchMessengerUserChats()
    router.replace({name: 'messenger'})
    mainStore.setActiveChat(undefined)
    Notify.create({
      message: 'Chat wurde gelöscht.'
    })
  })
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_chat_message = _resolveComponent("q-chat-message")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_spinner_tail = _resolveComponent("q-spinner-tail")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(mainStore).userActiveChat)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.$q.screen.lt.md)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        class: "q-px-none",
                        dense: "",
                        icon: "fas fa-arrow-left",
                        round: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.replace({name: 'messenger'})))
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getChatReceiver(_unref(mainStore).userActiveChat?.participants), (participant) => {
                    return (_openBlock(), _createBlock(_component_q_avatar, {
                      key: participant.id,
                      color: "grey-3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_no_ssr, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_img, {
                              src: participant.avatar?.path,
                              "no-spinner": ""
                            }, null, 8, ["src"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (!getChatReceiver(_unref(mainStore).userActiveChat?.participants)?.length)
                    ? (_openBlock(), _createBlock(_component_q_avatar, {
                        key: 0,
                        color: "grey-3"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (getChatReceiver(_unref(mainStore).userActiveChat.participants)?.length)
                    ? (_openBlock(), _createBlock(ProfileName, {
                        key: 0,
                        owner: getChatReceiver(_unref(mainStore).userActiveChat.participants)[0],
                        truncate: ""
                      }, null, 8, ["owner"]))
                    : (_openBlock(), _createElementBlock("i", _hoisted_8, "Nutzer gelöscht"))
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_q_btn, {
                flat: "",
                round: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "fas fa-ellipsis-vertical" }),
                  _createVNode(_component_q_menu, { "auto-close": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_list, { style: {"min-width":"150px"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item, {
                            clickable: "",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (deactivateChat(_unref(mainStore).userActiveChat.uuid)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode("Chat archivieren")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(mainStore).userActiveChat?.advert)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([{ 'q-px-md': _ctx.$q.screen.lt.md }, "q-mb-md"])
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(SrcsetImg, {
                ratio: 4/3,
                src: _unref(mainStore).userActiveChat.advert.cover_image?.image_thumbnail?.webp,
                srcset: _unref(mainStore).userActiveChat.advert.cover_image?.image_thumbnail?.srcset,
                class: "rounded-borders",
                fit: "cover",
                "show-placeholder": !_unref(mainStore).userActiveChat.advert.deleted || _unref(mainStore).userActiveChat.advert.active
              }, {
                default: _withCtx(() => [
                  (!_unref(mainStore).userActiveChat.advert.active || _unref(mainStore).userActiveChat.advert.deleted)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          (_unref(mainStore).userActiveChat.advert.deleted)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" gelöscht ")
                              ], 64))
                            : (!_unref(mainStore).userActiveChat.advert.active)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(" inaktiv ")
                                ], 64))
                              : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["src", "srcset", "show-placeholder"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(mainStore).userActiveChat?.advert?.seo_title), 1),
              _createElementVNode("div", _hoisted_15, [
                (_unref(mainStore).userActiveChat.advert.deleted)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" Inserat gelöscht ")
                    ], 64))
                  : (!_unref(mainStore).userActiveChat.advert.active)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" Inserat inaktiv ")
                      ], 64))
                    : _createCommentVNode("", true)
              ]),
              (_unref(mainStore).userActiveChat.advert?.active)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      rounded: "",
                      unelevated: "",
                      "no-caps": "",
                      to: {
                name: 'listingDetail',
                params: { uuid: _unref(mainStore).userActiveChat.advert.uuid },
              }
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Zum Inserat ")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_unref(mainStore)?.userActiveChat?.item)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([{ 'q-px-md': _ctx.$q.screen.lt.md }, "q-mb-md"])
        }, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(SrcsetImg, {
                ratio: 3/4,
                src: _unref(mainStore).userActiveChat.item.cover_image?.image_thumbnail?.webp,
                srcset: _unref(mainStore).userActiveChat.item.cover_image?.image_thumbnail?.srcset,
                class: "rounded-borders",
                fit: "cover",
                "show-placeholder": _unref(mainStore).userActiveChat.item.status !== 'deleted'
              }, {
                default: _withCtx(() => [
                  (['inactive', 'deleted'].includes(_unref(mainStore).userActiveChat.item.status))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          (_unref(mainStore).userActiveChat.item.status === 'deleted')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" gelöscht ")
                              ], 64))
                            : (_unref(mainStore).userActiveChat.item.status === 'inactive')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(" inaktiv ")
                                ], 64))
                              : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["src", "srcset", "show-placeholder"])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(mainStore).userActiveChat?.item?.seo_title), 1),
              _createElementVNode("div", _hoisted_23, [
                (_unref(mainStore).userActiveChat.item.status === 'deleted')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" Inserat gelöscht ")
                    ], 64))
                  : (_unref(mainStore).userActiveChat.item.status === 'inactive')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" Inserat inaktiv ")
                      ], 64))
                    : _createCommentVNode("", true)
              ]),
              (_unref(mainStore).userActiveChat.item.status === 'active')
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      rounded: "",
                      unelevated: "",
                      "no-caps": "",
                      to: {
                name: 'item-detail',
                params: { uuid: _unref(mainStore).userActiveChat.item.uuid },
              }
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Zum Inserat ")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(_component_q_card, {
        bordered: "",
        class: "absolute-full bg-grey-3",
        flat: "",
        square: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_card_section, {
            ref_key: "chatContainer",
            ref: chatContainer,
            class: "full-height scroll-y flex",
            onScroll: _unref(onChatScroll)
          }, {
            default: _withCtx(() => [
              (messages.value?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (msg) => {
                      return (_openBlock(), _createBlock(_component_q_chat_message, {
                        key: msg.uuid,
                        avatar: msg.sender?.avatar?.path,
                        "bg-color": 'white',
                        name: !msg.sender && _unref(mainStore)?.userActiveChat?.participants?.length > 1 ? 'Nutzer gelöscht' : undefined,
                        sent: msg.is_own,
                        "text-color": 'black'
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(msg.content), 1)
                        ]),
                        stamp: _withCtx(() => [
                          _createVNode(_component_q_no_ssr, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$filters ? _ctx.$filters.i18nDateTime(msg.created_at, {
                      month: 'numeric',
                      weekday: undefined,
                    }) : undefined), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["avatar", "name", "sent"]))
                    }), 128))
                  ]))
                : (_unref(mainStore).userChatLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_q_icon, {
                          class: "text-grey-3",
                          name: "fas fa-comments",
                          size: "3rem"
                        }),
                        _cache[6] || (_cache[6] = _createElementVNode("div", null, [
                          _createElementVNode("small", null, "Keine Nachrichten")
                        ], -1))
                      ])
                    ]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onScroll"]), [
            [_vShow, messages.value && _unref(mainStore).userActiveChat && !_unref(mainStore).userChatLoading]
          ]),
          (_unref(mainStore).userChatLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createVNode(_component_q_spinner_tail, {
                  color: "white",
                  size: "5rem"
                })
              ]))
            : (!messages.value?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_icon, {
                      class: "text-grey-4",
                      name: "fas fa-comments",
                      size: "3rem"
                    })
                  ]),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "q-mt-sm text-small text-muted" }, "Keine Nachrichten", -1))
                ]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (_unref(mainStore).userActiveChat)
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("form", {
              onSubmit: _withModifiers(createMessage, ["prevent"])
            }, [
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_component_q_input, {
                  ref_key: "messageInput",
                  ref: messageInput,
                  modelValue: currentMessage.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentMessage).value = $event)),
                  autogrow: "",
                  class: "col-grow",
                  dense: "",
                  outlined: "",
                  placeholder: "Schreibe eine Nachricht",
                  type: "textarea",
                  maxlength: "6000",
                  onKeydown: inputOnKeyDown
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", null, [
                  _createVNode(_component_q_btn, {
                    id: "btn-submit",
                    disable: isSendingMessage.value,
                    loading: isSendingMessage.value,
                    flat: "",
                    unelevated: "",
                    round: "",
                    icon: "send",
                    type: "button",
                    onClick: _withModifiers(createMessage, ["prevent"])
                  }, null, 8, ["disable", "loading"])
                ])
              ])
            ], 32),
            (!_ctx.$q.platform.is.mobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_34, _cache[8] || (_cache[8] = [
                  _createElementVNode("small", null, "Strg + Enter zum Absenden", -1)
                ])))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})