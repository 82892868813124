import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row container q-ma-lg q-col-gutter-y-lg" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "c-footer-links row q-col-gutter-lg" }
const _hoisted_4 = { class: "col-12 col-sm-6 col-md-4 flex column" }
const _hoisted_5 = { class: "col-12 col-sm-6 col-md-4 flex column" }
const _hoisted_6 = { class: "col-12 col-md-4" }

import SocialMediaLogos from 'components/SocialMediaLogos.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'FooterBar',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", {
    class: "c-footer bg-white q-footer--bordered",
    style: _normalizeStyle({
      'margin-bottom': _ctx.$q.screen.lt.sm ? '60px' : undefined
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-h6 q-mb-sm" }, "Camper Trader", -1)),
            _createVNode(_component_router_link, { to: {name: 'home'} }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Startseite")
              ])),
              _: 1
            }),
            _cache[6] || (_cache[6] = _createElementVNode("a", { href: "/blog/" }, "Blog", -1)),
            _createVNode(_component_router_link, { to: {name: 'dealer-landing'} }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Händler werden")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, { to: {name: 'partner'} }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Unsere Partner")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, { to: {name: 'about-us'} }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Über uns")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, { to: {name: 'pricing'} }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Preise")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-h6 q-mb-sm" }, "Hilfe", -1)),
            _createVNode(_component_router_link, { to: {name: 'faq-help'} }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("FAQ & Hilfe")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, { to: {name: 'privacy'} }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Datenschutz")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, { to: {name: 'imprint'} }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Impressum")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, { to: {name: 'contact'} }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Kontakt")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          _createVNode(SocialMediaLogos)
        ])
      ])
    ])
  ], 4))
}
}

})