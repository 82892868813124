import script from "./DrawerMenu.vue?vue&type=script&lang=ts&setup=true"
export * from "./DrawerMenu.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/ripple/Ripple.js';
import qInstall from "../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QList,QItem,QItemSection,QBtn});qInstall(script, 'directives', {Ripple});
