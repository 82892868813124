import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_no_ssr, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_select, _mergeProps({
          modelValue: $setup.searchLocation,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchLocation) = $event)),
          "hide-dropdown-icon": true,
          options: $setup.autocompleteOptions,
          behavior: "menu",
          dense: "",
          clearable: "",
          placeholder: "Überall",
          "fill-input": "",
          "hide-selected": ""
        }, _ctx.$attrs, {
          "input-debounce": "350",
          label: "Wo?",
          "stack-label": "",
          "use-input": "",
          onFilter: $setup.filterFn,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $setup.searchLocation)))
        }), _createSlots({
          prepend: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "fa-solid fa-location-dot",
              size: "xs"
            })
          ]),
          _: 2
        }, [
          ($props.showGPS)
            ? {
                name: "after",
                fn: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    flat: "",
                    round: "",
                    loading: $setup.loadingLocation,
                    onClick: $setup.getLocation
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, { name: "my_location" })
                    ]),
                    _: 1
                  }, 8, ["loading", "onClick"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1040, ["modelValue", "options", "onFilter"])
      ]),
      _: 1
    })
  ]))
}