import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {LIcon, LMarker} from '@vue-leaflet/vue-leaflet';
import {computed} from 'vue';

const businessIconSize = 20

export default /*@__PURE__*/_defineComponent({
  __name: 'MapAdvertIcon',
  props: ['latLng'],
  setup(__props) {



const businessIconAnchor = [businessIconSize / 2, businessIconSize / 2]
const businessIconSizePx = computed(() => `${businessIconSize}px`)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(LMarker), { "lat-lng": __props.latLng }, {
    default: _withCtx(() => [
      _createVNode(_unref(LIcon), {
        "icon-anchor": businessIconAnchor,
        "class-name": "c-business-icon",
        "icon-size": [businessIconSize, businessIconSize]
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "c-business-icon",
            style: _normalizeStyle({width: businessIconSizePx.value, height: businessIconSizePx.value})
          }, null, 4)
        ]),
        _: 1
      }, 8, ["icon-size"])
    ]),
    _: 1
  }, 8, ["lat-lng"]))
}
}

})